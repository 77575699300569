import {
  Box,
  Divider,
  Typography,
} from "@material-ui/core";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EditCompanyDetailes } from "../../redux/action/Company&User/EditCompanyDetails";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
import { toast } from "react-toastify";

const CompanyProfile = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [profile, setProfile] = useState();
  const [LastActive, setLastActive] = useState();

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = async () => {
    await axios
      .get(`/api/v1/admin/GetCompanysById?CategoryId=${id}`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setProfile(res.data);
      })
      .catch((err) => {});
  };

  const [formData, setFormData] = useState({
    companyName: "",
    email: "",
    phone: "",
    gst: "",
    AddressLine: "",
    city: "",
    pincode: "",
    bankAccountNo: "",
    ifsc: "",
    branchName: "",
    website: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setProfile(e.target.value);
  };

  const UpdateProfile = async () => {
    dispatch(EditCompanyDetailes(id, formData));
    toast.success("Profile Updated Succesfully");
  };

  const LastActiveData = async () => {
    await axios
      .get(`/api/v1/admin/AdminCompanyDetails/LastActiveCompanyDate`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setLastActive(res.data.data);
      })
      .catch((err) => {});
  };

  const formatDate = (dateString) => {
    const parts = dateString.split(", ");
    if (parts.length === 2) {
      const datePart = parts[0];
      return datePart;
    }
    return dateString;
  };

  useEffect(() => {
    profileData();
    LastActiveData();
  }, []);

  return (
    <>
      <Box component="div" className="company-profile-container">
        <Box className="page-heading-section">
          <Box className="page-heading">
            <h5>Company Profile</h5>
            <Typography>User ID : #{profile?.at(0)?._id}</Typography>
            {/* <Typography>Last log In : 1/4/2023 2:39 pm</Typography> */}
            <Typography>
              Last Seen :{" "}
              {LastActive?.filter(
                (item) => item?.loginUser === profile?.at(0)?._id
              ).reduce((idx, item) => {
                return <span>{formatDate(item?.lastSeen)}</span>;
              }, "--")}
            </Typography>
          </Box>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setIsDisabled(!isDisabled);
            }}
            style={{
              color: "rgba(68, 95, 210, 1)",
              marginLeft: "700px",
            }}
          >
            <Edit />
          </IconButton>
        </Box>
        <Divider />
        <Box className="single-row">
          <Box>
            <Typography> Business Name</Typography>
            <input
              type="text"
              variant="outlined"
              value={profile?.at(0)?.companyName}
              name="companyName"
              disabled={true}
              className={true && "disabled"}
              onChange={handleInputChange}
            />
          </Box>
         
        </Box>
        <Divider />

        <Box className="single-row">
          <Box>
            <Typography> E mail</Typography>
            <input
              type="text"
              variant="outlined"
              value={profile?.at(0)?.email}
              name="email"
              disabled={isDisabled}
              className={isDisabled && "disabled"}
              onChange={handleInputChange}
            />
          </Box>
          <Box>
            <Typography> Phone Number</Typography>
            <input
              type="text"
              variant="outlined"
              name="phone"
              onChange={handleInputChange}
              value={profile?.at(0)?.phone}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
        </Box>
        <Divider />
        <Box className="single-row">
          <Box>
            <Typography> Company Logo</Typography>
            <img
              src={profile?.at(0)?.CompanyAvatar?.url}
              alt=""
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                border: "1px solid",
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box className="single-row">
          <Box>
            <Typography> Website</Typography>
            <input
              type="text"
              variant="outlined"
              value={profile?.at(0)?.website}
              name="website"
              onChange={handleInputChange}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
        </Box>
        <Divider />
        <Box className="single-row">
          <Box>
            <Typography> GST No.</Typography>
            <input
              type="text"
              variant="outlined"
              value={profile?.at(0)?.gst}
              name="gst"
              onChange={handleInputChange}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
        </Box>
        <Divider />
        <Box className="single-row">
          <Box sx={{ flex: "0 0 100% !important" }}>
            <Typography> Address</Typography>
            <input
              type="text"
              style={{ width: "73%" }}
              variant="outlined"
              value={profile?.at(0)?.CompanyAddress?.AddressLine}
              name="AddressLine"
              onChange={handleInputChange}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
        </Box>
        <Box className="single-row">
          <Box>
            <Typography> City</Typography>
            <input
              type="text"
              variant="outlined"
              value={profile?.at(0)?.CompanyAddress?.City}
              name="city"
              onChange={handleInputChange}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
          <Box>
            <Typography> Postal Code</Typography>
            <input
              type="text"
              variant="outlined"
              name="pincode"
              onChange={handleInputChange}
              value={profile?.at(0)?.CompanyAddress?.PinCode}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
        </Box>
        <Divider />
        <Box className="single-row">
          <Box>
            <Typography> Account Number</Typography>
            <input
              type="text"
              variant="outlined"
              value={profile?.at(0)?.bankdetails?.bankAccountNo}
              name="bankAccountNo"
              onChange={handleInputChange}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
          <Box>
            <Typography> IFSC Code</Typography>
            <input
              type="text"
              variant="outlined"
              name="ifsc"
              onChange={handleInputChange}
              value={profile?.at(0)?.bankdetails?.ifsc}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
        </Box>
        <Box className="single-row">
          <Box sx={{ flex: "0 0 100% !important" }}>
            <Typography> Branch Address</Typography>
            <input
              type="text"
              style={{ width: "73%" }}
              variant="outlined"
              value={profile?.at(0)?.bankdetails?.branchName}
              name="branchName"
              onChange={handleInputChange}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
        </Box>
        <Divider />
        <Box className="single-row">
          
          <Box>
            <Typography> Date Joined</Typography>
            <input
              type="text"
              variant="outlined"
              name=""
              value={new Date(profile?.at(0)?.memberships?.at(-1)?.MembershipStartDate).toLocaleDateString(
                "en-GB",
                {
                  timeZone: "UTC",
                }
              )}
              disabled={isDisabled}
              className={isDisabled && "disabled"}
            />
          </Box>
        </Box>
        <br />
        <br />
        <Box className="button-container">
          <button
            className="cancel-button"
            onClick={() => {
              navigate("/admindashboard/companies");
            }}
          >
            Cancel
          </button>
          <button className="save-button" onClick={UpdateProfile}>
            Save
          </button>
        </Box>
        <br />
        <br />
      </Box>
    </>
  );
};

export default CompanyProfile;
