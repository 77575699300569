import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Button,
  Paper,
  Divider,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useFetchOrders } from "../../Hooks/AllRecords/useAllRecords";
import * as Styles from "../../common/Styles";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import Searchbar from "../../common/Searchbar";

function ProductOrders() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const {
    data: fetchOrders,
    isLoading: invoicesLoading,
    refetch: ordersRefetch,
  } = useFetchOrders(currentPage, searchData);

  const getStatus = (data) => {
    const {
      escrowSellerReleaseResponse,
      escrowRefundResponse,
      walletRefund,
      escrowPayment,
      InvoiceData,
      PurchaseOrderData,
      BuyerOrderStatus,
      IsMedia,
      IsVoucher,
      OrderTracking,
      MediaStatus,
      VoucherStatus,
    } = data;

    if (IsVoucher) {
      if (escrowRefundResponse?.success) return "INR Refunded";
      if (walletRefund === "success") return "Wallet Refunded";
      if (escrowSellerReleaseResponse?.success) return "Released Called";
      if (VoucherStatus) return VoucherStatus;
      if (escrowPayment?.success === "true") return "Payment Done";
      if (!escrowPayment?.success && !escrowSellerReleaseResponse?.success)
        return "Payment Pending";
    }
    if (escrowRefundResponse?.success) return "INR Refunded";
    if (walletRefund === "success") return "wallet Refunded";
    if (escrowSellerReleaseResponse?.success) return "Released Called";
    if (!IsVoucher && !IsMedia && OrderTracking)
      return OrderTracking?.at(OrderTracking?.length - 1)?.Status;
    if (!IsVoucher && IsMedia && MediaStatus) return MediaStatus;
    if (BuyerOrderStatus === "success" && !IsMedia && !IsVoucher)
      return "Buyer Confirmed";
    // if (SellerOrderStatus === "success" && !IsMedia && !IsVoucher)
    //   return "Delivered";
    if (escrowPayment?.success === "true") return "Payment Done";
    if (InvoiceData) {
      if (InvoiceData?.SellerInvoiceAcceptanceStatus === "Accepted") {
        if (InvoiceData?.BuyerInvoiceAcceptanceStatus === "Pending")
          return "PI Issued";
        if (InvoiceData?.BuyerInvoiceAcceptanceStatus === "Accepted")
          return "PI Accepted";
        if (InvoiceData?.BuyerInvoiceAcceptanceStatus === "Rejected")
          return "PI Rejected";
      }
    }
    if (PurchaseOrderData) {
      if (
        PurchaseOrderData?.BuyerOrderStatus === "Pending" &&
        PurchaseOrderData?.SellerOrderStatus === "Pending"
      )
        return "PO Pending";
      if (
        PurchaseOrderData?.BuyerOrderStatus === "Accepted" &&
        PurchaseOrderData?.SellerOrderStatus === "Pending"
      )
        return "PO Issued";
      if (PurchaseOrderData?.BuyerOrderStatus === "Rejected")
        return "PO Cancelled";
      if (
        PurchaseOrderData?.BuyerOrderStatus === "Accepted" &&
        PurchaseOrderData?.SellerOrderStatus === "Rejected"
      )
        return "PO Rejected";
    }
    return "";
  };

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    ordersRefetch();
  }, [currentPage, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  useEffect(() => {
    // if (fetchOrders && fetchOrders?.totalPages !== undefined) {
    setInitialPaginationCount(fetchOrders?.totalPages);
    // }
  }, [fetchOrders]);

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "590px",
        width: "99%",
        // overflowY: "scroll",
      }}
    >
      <React.Fragment>
        <Paper elevation={0}>
          <Box
            className="page-heading-section"
            style={{
              justifyContent: "start",
              gap: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box className="page-heading">
              <h5>
                Release Funds Only After Successful Delivery , Confirm from
                Buyer First - Undo is Offline Process{" "}
              </h5>
              <Typography>
                Barter Coins | Gst | BXI Commission | Others{" "}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2, mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "97%",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Box sx={buddge}>
                <Typography sx={textBudge}>
                  {fetchOrders instanceof Object ? (
                    <Typography sx={textBudge}>
                      Total Order(s): {fetchOrders?.totalDataCount || 0}
                    </Typography>
                  ) : (
                    <Typography sx={textBudge}>
                      Total Order(s):{fetchOrders?.totalDataCount || 0}
                    </Typography>
                  )}
                </Typography>
              </Box>
              <Searchbar onSendData={handleChildData} />
            </Box>
            <Box
              sx={{
                background: "rgba(250, 251, 252, 1)",
                p: 0,
                mt: 0,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "80vw",
                  minWidth: "80vw",
                  height: "auto",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: "100%",
                  }}
                >
                  <TableContainer sx={TableContainerStyle}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>No </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>
                              Buyer Company{" "}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>
                              Seller Company{" "}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>
                              Order Id{" "}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>Type </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>Date </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>
                              INR Amount{" "}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>
                              Total Coins{" "}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>
                              Total Amount{" "}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>
                              Status{" "}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Typography sx={TableTitleStyle}>
                              Actions{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fetchOrders?.data?.map((data, idx) => {
                          
                          if(data?.OrderId=== "BXI/INLL/Mar25/29"){
                            console.log("sdihufishdfiug",data)
                          }
                          const formattedDate = data
                            ? formatDate(data?.PoDate)
                            : "";

                          const status = getStatus(data);

                          let TotalAdditionalCostWtihoutGSTInBXI = 0;
                          let TotalPriceWithoutGST = 0;

                          data?.PurchaseOrderData?.ProductData?.map(
                            (el, idx) => {
                              if (data?.IsVoucher) {
                                TotalAdditionalCostWtihoutGSTInBXI +=
                                  el?.AdditionalCost
                                    ?.TotalAdditionalCostWtihoutGSTInBXI;
                              } else {
                                TotalAdditionalCostWtihoutGSTInBXI +=
                                  el?.AdditionCost
                                    ?.TotalAdditionalCostWtihoutGSTInBXI;
                              }
                              TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
                            }
                          );

                          return (
                            <TableRow>
                              <TypographyComponent>
                                {(currentPage - 1) * 10 + idx + 1}
                              </TypographyComponent>
                              <TypographyComponent width={250}>
                                {
                                  data?.PurchaseOrderData?.BuyerDetails
                                    ?.BuyerCompanyName
                                }
                                <br />
                                {data?.PurchaseOrderData?.BuyerDetails?.Address
                                  ?.City === "Not Found"
                                  ? ""
                                  : data?.PurchaseOrderData?.BuyerDetails
                                      ?.Address?.City}
                              </TypographyComponent>
                              <TypographyComponent width={250}>
                                {
                                  data?.PurchaseOrderData?.SellerDetails
                                    ?.SellerCompanyName
                                }
                                <br />
                                {data?.PurchaseOrderData?.SellerDetails?.Address
                                  ?.City === "Not Found"
                                  ? ""
                                  : data?.PurchaseOrderData?.SellerDetails
                                      ?.Address?.City}
                              </TypographyComponent>
                              <TypographyComponent>
                                {data?.OrderId}
                              </TypographyComponent>
                              <TypographyComponent>
                                {data?.IsVoucher
                                  ? "Voucher"
                                  : data?.IsMedia
                                  ? "Media"
                                  : "Product"}
                              </TypographyComponent>
                              <TypographyComponent>
                                {formattedDate}
                              </TypographyComponent>
                              <TypographyComponent width={100}>
                                {data?.INRDetails?.TotalInrToPay?.toFixed(2)}
                              </TypographyComponent>
                              <TypographyComponent width={100}>
                                {(
                                  (TotalAdditionalCostWtihoutGSTInBXI || 0) +
                                  (TotalPriceWithoutGST || 0) -
                                  data?.INRDetails?.TDS
                                ).toFixed(2) || 0}
                              </TypographyComponent>
                              <TypographyComponent width={100}>
                                {(
                                  (data?.INRDetails?.TotalInrToPay || 0) +
                                  (TotalAdditionalCostWtihoutGSTInBXI || 0) +
                                  (TotalPriceWithoutGST || 0) -
                                  data?.INRDetails?.TDS
                                ).toFixed(2) || 0}
                              </TypographyComponent>
                              <TypographyComponent>
                                {/* {data?.escrowSellerReleaseResponse?.success &&
                                data?.escrowBXIReleaseResponse?.success &&
                                data?.Wallet_transaction_status === "released"
                                  ? "Success"
                                  : "Pending"} */}
                                <Box
                                  sx={{
                                    background: "#e3e7f6",
                                    borderRadius: 1.5,
                                    minWidth: "120px",
                                    width: "auto",
                                  }}
                                >
                                  <Typography
                                    align="center"
                                    sx={{
                                      color: "#445fd2",
                                      fontFamily: "poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      fontStyle: "normal",
                                      lineHeight: "30px",
                                      width: "100%",
                                    }}
                                  >
                                    {status}
                                  </Typography>
                                </Box>
                              </TypographyComponent>
                              <TypographyComponent>
                                {data?.escrowPayment?.success === "true" && (
                                  <Button
                                    onClick={() => {
                                      navigate(
                                        `/admindashboard/escrow_release/${data?._id}`
                                      );
                                    }}
                                  >
                                    Release
                                  </Button>
                                )}
                              </TypographyComponent>
                              {/* <TypographyComponent>
                                {!data?.IsMedia &&
                                  !data?.IsVoucher &&
                                  data?.BuyerOrderStatus === "success" &&
                                  data?.SellerOrderStatus === "success" &&
                                  data?.escrowPayment?.success === "true" && (
                                    <Button
                                      onClick={() => {
                                        navigate(
                                          `/admindashboard/escrow_release/${data?._id}`
                                        );
                                      }}
                                    >
                                      Release
                                    </Button>
                                  )}
                                {data?.IsVoucher &&
                                  data?.VoucherStatus === "Active" &&
                                  data?.BuyerOrderStatus === "success" &&
                                  data?.escrowPayment?.success === "true" && (
                                    <Button
                                      onClick={() => {
                                        navigate(
                                          `/admindashboard/escrow_release/${data?._id}`
                                        );
                                      }}
                                    >
                                      Release
                                    </Button>
                                  )}
                              </TypographyComponent> */}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
                mx: "auto",
              }}
              mt={2}
            >
              <Stack>
                <StyledPagination
                  size="medium"
                  count={initialPaginationCount}
                  color="primary"
                  showFirstButton
                  showLastButton
                  page={currentPage}
                  onChange={handlePageChange}
                  strokeWidth={currentPage}
                />
              </Stack>
            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    </Paper>
  );
}

export default ProductOrders;

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#000",
};

const TypographyComponent = ({ children, ...props }) => {
  return (
    <TableCell>
      <Typography sx={{ ...TextStyleCommon, width: props.width }}>
        {children}
      </Typography>
    </TableCell>
  );
};

const TableContainerStyle = {
  minHeight: "500px",
  maxHeight: "500px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};
