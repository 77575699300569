import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { GetTokenFunc } from "../../utils/GetToken";
import PageLoader from "../CommonActions/PageLoader";
import CompanyTypeComp from "../AllRecords/CompanyTypeComp";
import { toast } from "react-toastify";

const BulkUploadApprovals = () => {
  const [FilesData, setFilesData] = useState();
  const [ItemId, setItemId] = useState();
  const [Loader, setLoader] = useState(false);
  console.log("FilesDataFilesData", ItemId);

  const GetAllFiles = async () => {
    setLoader(true);
    await axios
      .post("publiq_bulk_upload/get_all_admin_error_file", {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        console.log("sdfdsada", res.data.files);
        setFilesData(res.data.files);
        setLoader(false);
      })
      .catch((err) => {});
  };

  const handleDownload = (Link, Filename) => {
    if (!Link) return alert("No link available to download!");

    const filename = Filename || "output-file.xlsx";
    const a = document.createElement("a");
    a.href = Link;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Custom confirmation dialog
    const customConfirm = document.createElement("div");
    customConfirm.style = `
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
    width: 300px;
  `;

    customConfirm.innerHTML = `
    <p style="font-size: 16px;">Did the file download successfully?</p>
    <button style="margin-top: 10px; padding: 10px 20px; background: #4CAF50; color: #fff; border: none; cursor: pointer; border-radius: 5px;">Confirm</button>
    <button style="margin-top: 10px; margin-left: 10px; padding: 10px 20px; background: #f44336; color: #fff; border: none; cursor: pointer; border-radius: 5px;">Cancel</button>
  `;

    const confirmButton = customConfirm.querySelector("button:nth-child(2)");
    const cancelButton = customConfirm.querySelector("button:nth-child(3)");

    confirmButton.onclick = () => {
      document.body.removeChild(customConfirm);
    };

    cancelButton.onclick = () => {
      document.body.removeChild(customConfirm);
      alert("Download may have failed. Please try again.");
    };

    document.body.appendChild(customConfirm);
  };

  const inputFile = useRef(null);
  const onButtonClick = (id) => {
    // Create custom confirmation dialog
    const customConfirm = document.createElement("div");
    customConfirm.style = `
      position: fixed;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      text-align: center;
      width: 300px;
    `;

    customConfirm.innerHTML = `
      <p style="font-size: 16px;">Are you sure you want to Upload File?</p>
      <button style="margin-top: 10px; padding: 10px 20px; background: #4CAF50; color: #fff; border: none; cursor: pointer; border-radius: 5px;">Confirm</button>
      <button style="margin-top: 10px; margin-left: 10px; padding: 10px 20px; background: #f44336; color: #fff; border: none; cursor: pointer; border-radius: 5px;">Cancel</button>
    `;

    const confirmButton = customConfirm.querySelector("button:nth-child(2)");
    const cancelButton = customConfirm.querySelector("button:nth-child(3)");

    confirmButton.onclick = () => {
      document.body.removeChild(customConfirm);
      // Proceed with file upload logic
      inputFile.current.click();
      setItemId(id);
    };

    cancelButton.onclick = () => document.body.removeChild(customConfirm);

    document.body.appendChild(customConfirm);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const myForm = new FormData();
    myForm.append("file", file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
    };

    reader.onerror = (err) => {
      console.error("Error reading the file:", err);
    };
    reader?.readAsArrayBuffer(file);
    uploadExcelFile(file);
  };

  const uploadExcelFile = async (myForm) => {
    try {
      const formData = new FormData();
      formData.append("file", myForm);
      formData.append("id", ItemId);
      const { data } = await axios.post(
        "publiq_bulk_upload/bulk_error_file_upload_Admin",
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      if (data?.file_Uploaded === true) {
        toast.success("File uploaded successfully", {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      GetAllFiles();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.name, {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Network Error:", error.message);
      }
    }
  };

  useEffect(() => {
    GetAllFiles();
  }, []);

  return (
    <Paper
      sx={{
        width: "100%",
        bgcolor: "transparent",
        maxHeight: "90vh",
        overflow: "scroll",
      }}
      elevation={0}
    >
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={BoxStyle}>
        <Typography
          sx={{
            width: "100%",
            textAlign: "center",
            color: "#6b7a99",
            fontSize: "16px",
            fontWeight: "bold",
            alignItems: "center",
            height: "30px",
          }}
        >
          Uploaded Files
        </Typography>
      </Grid>
      {Loader ? (
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            pl: "45%",
          }}
        >
          <PageLoader />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "80vw",
            minWidth: "80vw",
            height: "auto",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              width: "100%",
            }}
          >
            <TableContainer sx={TableContainerStyle}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>No.</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Company Name (Category)
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>File</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Download</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Upload</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Update Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FilesData?.length > 0 ? (
                    FilesData?.map((item, idx) => {
                      console.log(
                        "item?.user_uploaded_file",
                        item?.user_uploaded_file
                      );
                      return (
                        <TableRow key={idx}>
                          <TypographyComponent>{idx + 1}</TypographyComponent>
                          <TypographyComponent width={200}>
                            {item?.companyData?.companyName}
                            <br />
                            <CompanyTypeComp
                              companyTypeId={item?.companyData?.companyType}
                            />
                          </TypographyComponent>
                          <TypographyComponent width={300}>
                            <span
                              style={{
                                width: "100%",
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.user_file_name}
                            </span>
                          </TypographyComponent>
                          <TypographyComponent width={80}>
                            {" "}
                            <Button
                              variant="contained"
                              sx={planbtn}
                              onClick={() =>
                                handleDownload(
                                  item?.user_uploaded_file,
                                  item?.user_file_name
                                )
                              }
                            >
                              Download
                            </Button>
                          </TypographyComponent>
                          <TypographyComponent width={80}>
                            <input
                              type="file"
                              ref={inputFile}
                              onChange={handleFileUpload}
                              style={{ display: "none" }}
                            />
                            <Button
                              variant="contained"
                              onClick={() => onButtonClick(item?._id)}
                              sx={planbtn}
                              disabled={item?.IsAdminUploaded}
                            >
                              Upload
                            </Button>
                          </TypographyComponent>
                          <TypographyComponent width={80}>
                            {" "}
                            <Button
                              variant="contained"
                              sx={planbtn}
                              disabled={item?.Admin_Aprroved}
                            >
                              {item?.Admin_Aprroved ? "Approved" : "Pending"}
                            </Button>
                          </TypographyComponent>
                        </TableRow>
                      );
                    })
                  ) : (
                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        color: "#6b7a99",
                      }}
                    >
                      No Data Available
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
        }}
      >
        {/* {AllCoupon?.data?.length ? (
          <StyledPagination
            size="medium"
            count={initialPaginationCount}
            color="primary"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            strokeWidth={currentPage}
          />
        ) : null} */}
      </Box>
    </Paper>
  );
};

export default BulkUploadApprovals;

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const BoxStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const TableContainerStyle = {
  minHeight: "500px",
  maxHeight: "500px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
  width: "auto",
};

const planbtn = {
  bgcolor: "#445FD2",
  boxShadow: "none",
  textTransform: "none",
  width: "auto",
  height: "30px",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  my: 2,
};
const TypographyComponent = ({ children, ...props }) => {
  return (
    <TableCell>
      <Typography sx={{ ...TextStyleCommon, width: props.width }}>
        {children}
      </Typography>
    </TableCell>
  );
};
