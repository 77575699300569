import React, { useState, useEffect } from "react";
import CategoryDropdown from "../Layouts/CategoryDropdown";
import CompanyDropdown from "../Layouts/CompanyDropdown";
import { Delete, Edit } from "@mui/icons-material";
import {
  IconButton,
  Modal,
  Button,
  Box,
  Typography,
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Popup from "../Layouts/Popup/Popup";
import axios from "axios";
import CommonDelete from "../CommonActions/CommonDelete";
import { useDispatch, useSelector } from "react-redux";
import { AllCompanysDetails } from "../../redux/action/Company&User/AllCompanys";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import { toast, ToastContainer } from "react-toastify";
import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import { GetTokenFunc } from "../../utils/GetToken";
import { useGetcreditrequests } from "./Hooks";
import GetBalanceComp from "./GetComapnyBalance";
import LastPurchaseDate from "./LastPurchaseDate";
import LastSellDate from "./LastSellDate";
import RecoverBalanceComp from "./RecoverBalanceComp";
import CreditDueComp from "./CreditduebalanceComp";

export let Category = "";
export let CompanyStatus = "";
export let Membership = "";
export let Page = "";
export let SelectedCompany = "";
export let DateFilter = "";

const CompanyListing = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [id, setId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [activeTab, setActiveTab] = useState(2);
  const [content, setContent] = useState("");
  const [companies, setCompanies] = useState();
  const [companystatus, setCompanystatus] = useState("");
  const [membership, setMembership] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [AllCompanyName, setAllCompanyName] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [date, setDate] = useState("");
  const [totalProducts, setTotalProducts] = useState([]);
  const [totalMembers, setTotalMembers] = useState([]);
  const [creditLineDetails, setCreditLineDetails] = useState([]);
  const [pendingCreditLineDetails, setPendingCreditLineDetails] = useState([]);
  const [defaultAmount, setDefaultAmount] = useState("");
  const [error, setError] = useState(false);
  const [companyIdDefaulte, setCompanyIdDefaulte] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const {
    data: requestData,
    isLoading: ApprovalDataLoading,
    isError: ApprovalDataError,
    refetch: ApprovalDataRefetch,
  } = useGetcreditrequests();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { AllCompany, loading } = useSelector((state) => state.AllCompany);
  useEffect(() => {
    if (AllCompany && AllCompany?.finalCount !== undefined) {
      setInitialPaginationCount(AllCompany?.finalCount);
    }
  }, [AllCompany?.finalCount]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloses = () => {
    setOpen(false);
    setError(false);
  };

  Category = category;
  CompanyStatus = companystatus;
  Membership = membership;
  Page = currentPage;
  SelectedCompany = selectedCompany;
  DateFilter = date;

  const DeleteCompany = async () => {
    await axios
      .post(
        `/api/v1/admin/DeleteCompanyById/${id}`,
        {},
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => { })
      .catch((err) => { });
  };

  useEffect(() => {
    dispatch(
      AllCompanysDetails(category, companystatus, membership, currentPage)
    );
  }, [
    dispatch,
    category,
    companystatus,
    membership,
    selectedCompany,
    date,
    currentPage,
  ]);

  const filterByCompany = async () => {
    await axios
      .get(`product/get_allproducts_bycompany`, { withCredentials: true })
      .then((res) => {
        setAllCompanyName(res.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    filterByCompany();
  }, []);

  const handleDelete = () => {
    DeleteCompany();
    setIsDeleteModalOpen(false);
  };

  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditButtonClick = (id) => {
    navigate(`/admindashboard/companylanding/${id}`);
  };

  const handleCompanyChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCompany(selectedValue);
  };

  const GetAllTotalProductsCount = async () => {
    await axios
      .get(`/api/v1/admin/GetAllTotalProductsCount`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setTotalProducts(res.data);
      })
      .catch((err) => { });
  };

  const GetAllTotalUserCount = async () => {
    await axios
      .get(`/api/v1/admin/GetAllTotalUserCount`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setTotalMembers(res.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    GetAllTotalProductsCount();
    GetAllTotalUserCount();
  }, []);

  const handleButtonClick = (companyId) => {
    handleClickOpen();
    setCompanyIdDefaulte(companyId);
  };

  const handleSave = async () => {
    try {
      if (defaultAmount.trim() === "") {
        setError(true);
      } else {
        setError(false);

        const response = await axios.post(
          `/api/v1/admin/AdminCompanyDetails/CreateNewDefaulteCase`,
          {
            companyId: companyIdDefaulte,
            amount: defaultAmount,
            status: true,
          },
          {
            headers: {
              authorization: `Bearer ${GetTokenFunc()}`,
            },
          }
        );

        if (response && response.data) {
          toast.success("Amount Added successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          console.error("No data received after saving.");
        }

        handleCloses();
      }
    } catch (error) {
      console.error("Error while saving default amount:", error);
    }
  };

  const GetPendingCreditDetails = async () => {
    await axios
      .get(`/api/v1/admin/GetCreditLinePendingAmount`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setPendingCreditLineDetails(res.data);
      })
      .catch((err) => { });
  };

  const GetAllCreditDetails = async () => {
    const Wallet_Url = "https://wallet-api.development-bxi.unada.in";

    await axios
      .get(`${Wallet_Url}/api/v1/chart/GetAllBxiCreditDetails`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setCreditLineDetails(res.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    GetAllCreditDetails();
    GetPendingCreditDetails();
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        // justifyContent: "flex-end",
        height: "600px",
        width: "99%",
        overflowX: "hidden",
      }}
    >
      <Box className="page-header-dropwon-section">
        <Box
          className="marketplace-filters"
          sx={{
            width: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={TypoStyle}>Select Category</Typography>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">All</option>
              <option value="63e38bddcc4c02b8a0c94b8a">Others</option>
              <option value="63e38b9ccc4c02b8a0c94b6f">Lifestyle</option>
              <option value="63e38bb9cc4c02b8a0c94b7b">FMCG</option>
              <option value="63e38b91cc4c02b8a0c94b69">Media</option>
              <option value="63e38ba3cc4c02b8a0c94b72">Hotel</option>
              <option value="63e38bbfcc4c02b8a0c94b7e">Airlines Tickets</option>
              <option value="63e38bc6cc4c02b8a0c94b81">Office Supply</option>
              <option value="63e38bb3cc4c02b8a0c94b78">Mobility</option>
              <option value="63e38bcecc4c02b8a0c94b84">Electronics</option>
              <option value="63e38b96cc4c02b8a0c94b6c">Textile</option>
              <option value="64218b189fe1b6ae750c11bd">
                Entertainment & Events
              </option>
              <option value="63e38bd5cc4c02b8a0c94b87">QSR</option>
            </select>
          </Box>
        </Box>
        <Box className="marketplace-filters">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={TypoStyle}>Select Member</Typography>
            <select onChange={handleCompanyChange} value={selectedCompany}>
              <option value="">All</option>
              {AllCompanyName.map((name, index) => (
                <option key={index} value={name?.companyName}>
                  {name?.companyName}
                </option>
              ))}
            </select>
          </Box>
        </Box>
      </Box>
      {/* <hr
        className="horizontal-line"
        style={{ color: 'rgba(240, 240, 240, 1)' }}
      /> */}{" "}
      <Divider />
      <Box
        className="marketplace-filters"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <label>Status</label>
          <select
            value={companystatus}
            onChange={(e) => setCompanystatus(e.target.value)}
          >
            <option value="">All</option>
            <option value="EMAIL">Email Verification</option>
            <option value="GST">GST Details</option>
            <option value="BANK_DETAILS">Pan Details</option>
            <option value="TNC">T&C</option>
            <option value="PAYMENT">Membership Plans</option>
            <option value="UNDER_REVIEW">Under Review</option>
            <option value="ONBOARDING_COMPLETE">Onboarding Complete</option>
          </select>
        </Box>

        <Box>
          <label>Membership</label>
          <select
            value={membership}
            onChange={(e) => setMembership(e.target.value)}
          >
            <option value="">All</option>
            <option value="advance">Advance Plan</option>
            <option value="basic">Standard Plan</option>
          </select>
        </Box>

        <Box>
          <label>Days</label>
          <select value={date} onChange={(e) => setDate(e.target.value)}>
            <option value="">All</option>
            <option value="7">7 Days</option>
            <option value="15">15 Days</option>
            <option value="30">1 Month</option>
            <option value="90">3 Month</option>
            <option value="180">6 Month</option>
            <option value="360">1 Year</option>
            <option value="1080">3 Year</option>
            <option value="1800">5 Year</option>
          </select>
        </Box>
      </Box>
      <Box sx={{ background: "rgba(250, 251, 252, 1)" }}>
        <Box className="products-filters-row">
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              {AllCompany instanceof Object ? (
                <Typography sx={textBudge}>
                  Total Members : {AllCompany?.allCompanySCount}
                </Typography>
              ) : (
                <Typography sx={textBudge}>Total Members: 0</Typography>
              )}
            </Typography>
          </Box>
        </Box>
        <div>
          {AllCompany?.Companys?.length <= 0 ? (
            <Box sx={setBox}>
              <img
                src={recyclebin}
                alt="recycle"
                style={{ height: "auto", width: "23%" }}
              />
              <Typography sx={empty}>Data is empty</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                maxWidth: "79vw",
                minWidth: "79vw",
                height: "auto",
                overflowX: "scroll",
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 5,
                    width: "100%",
                    // mr: '150%',
                    ml: "500px",
                  }}
                >
                  <PageLoader />
                </Box>
              ) : (
                <TableContainer sx={TableContainerStyle}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            ...TableTitleStyle,
                            minWidth: "60px",
                          }}
                        >
                          No .
                        </TableCell>
                        <TableCell sx={TableTitleStyle}>Member Name</TableCell>
                        <TableCell sx={TableTitleStyle}>Status</TableCell>
                        <TableCell sx={TableTitleStyle}>
                          Total Products
                        </TableCell>
                        <TableCell sx={TableTitleStyle}>Members</TableCell>
                        <TableCell sx={TableTitleStyle}>Date</TableCell>
                        <TableCell sx={TableTitleStyle}>Membership</TableCell>
                        <TableCell sx={TableTitleStyle}>
                          Membership Commission
                        </TableCell>
                        <TableCell sx={TableTitleStyle}> Purchase</TableCell>
                        <TableCell sx={TableTitleStyle}>
                          Last Purchase
                        </TableCell>
                        <TableCell sx={TableTitleStyle}>Sale</TableCell>
                        <TableCell sx={TableTitleStyle}>Last Sale</TableCell>
                        <TableCell
                          sx={{
                            ...TableTitleStyle,
                            minWidth: "200px",
                          }}
                        >
                          Total Accepted Credit
                        </TableCell>
                        <TableCell
                          sx={{
                            ...TableTitleStyle,
                            minWidth: "200px",
                          }}
                        >
                          Total Pending Credit
                        </TableCell>
                        <TableCell
                          sx={{
                            ...TableTitleStyle,
                            minWidth: "200px",
                          }}
                        >
                          Total Recovery Credit
                        </TableCell>
                        <TableCell sx={TableTitleStyle}>Action</TableCell>
                      </TableRow>
                    </TableHead>

                    {AllCompany?.Companys?.map((x, i) => {
                      console.log("xsdfhuysdf", x?.memberships?.at(-1)?.MembershipStartDate)
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                minWidth: "60px",
                                width: "auto",
                              }}
                            >
                              {i + 1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                ...TextStyleCommon,
                                minWidth: "280px",
                                width: "auto",
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                                justifyContent: "space-between",
                                alignItems: "space-between",
                              }}
                            >
                              <img
                                src={x?.CompanyAvatar?.url}
                                alt=""
                                style={{ height: "30px", width: "30px" }}
                              />
                              <Box>
                                <label>{x?.companyName}</label>
                                <br />
                                <span>Company ID : {x?._id} </span>
                              </Box>
                            </Typography>
                          </TableCell>
                          <TypographyComponent>
                            {x?.companyOnboardingStatus?.length > 0 ? (
                              <td className="blueText">
                                <label>
                                  {x?.companyOnboardingStatus === "BANK_DETAILS"
                                    ? "PAN_DETAILS"
                                    : x?.companyOnboardingStatus}
                                </label>
                                <span>&nbsp;</span>
                              </td>
                            ) : (
                              <td className="blueText">
                                <label>EMAIL_VERIFICATION</label>
                                <span>&nbsp;</span>
                              </td>
                            )}
                          </TypographyComponent>
                          <TypographyComponent>
                            <label>
                              {totalProducts
                                .filter(
                                  (product) => product?.company === x?._id
                                )
                                .reduce(
                                  (acc, product) =>
                                    acc + (product.productCount || 0),
                                  0
                                )}
                            </label>
                            <span>&nbsp;</span>
                          </TypographyComponent>

                          <TypographyComponent>
                            <label>
                              {totalMembers
                                .filter((member) => member?.company === x?._id)
                                .reduce(
                                  (acc, member) => acc + (member.Users || 0),
                                  0
                                )}
                            </label>
                            <span style={{ width: "10px" }}>&nbsp;</span>
                          </TypographyComponent>
                          <TypographyComponent>
                            {x?.memberships?.at(-1)?.MembershipStartDate ? new Date(x?.memberships?.at(-1)?.MembershipStartDate).toLocaleDateString(
                              "en-GB",
                              {
                                timeZone: "UTC",
                              }
                            ) : new Date(x?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                timeZone: "UTC",
                              }
                            )}
                          </TypographyComponent>

                          <TypographyComponent>
                            {x?.companyOnboardingStatus ===
                              "ONBOARDING_COMPLETE" ? (
                              x?.memberships ? (
                                <>
                                  {x?.memberships[x?.memberships?.length - 1]
                                    ?.Membership === "basic"
                                    ? "Standard"
                                    : x?.memberships[x?.memberships?.length - 1]
                                      ?.Membership === "advance"
                                      ? "Advance"
                                      : x?.ChoosedPlan}{" "}
                                </>
                              ) : null
                            ) : (
                              "--"
                            )}
                          </TypographyComponent>

                          <TypographyComponent>
                            {x?.companyOnboardingStatus ===
                              "ONBOARDING_COMPLETE"
                              ? x?.memberships
                                ? `${x?.memberships[x?.memberships?.length - 1]
                                  ?.MembershipPlan?.GST
                                }%`
                                : "--"
                              : "--"}
                          </TypographyComponent>

                          <TypographyComponent>
                            <label>
                              <GetBalanceComp id={x?._id} Purchase={true} />
                            </label>
                          </TypographyComponent>

                          <TypographyComponent>
                            <LastPurchaseDate companyId={x?._id} />
                          </TypographyComponent>

                          <TypographyComponent>
                            <label>
                              <GetBalanceComp id={x?._id} Sell={true} />
                            </label>
                          </TypographyComponent>

                          <TypographyComponent>
                            <LastSellDate companyId={x?._id} />
                          </TypographyComponent>

                          <TypographyComponent>
                            {creditLineDetails?.creditAcceptData
                              ?.filter(
                                (product) => product?._to_wallet_id === x?._id
                              )
                              .reduce((acc, product) => {
                                return acc + (product?._balance || 0);
                              }, 0)}
                          </TypographyComponent>
                          <TypographyComponent>
                            {/* {pendingCreditLineDetails?.creditLine
                              ?.filter(
                                (product) => product?.companyId === x?._id
                              )
                              .reduce((acc, product) => {
                                return (
                                  acc +
                                  parseInt(product?.requestedUserAmount || 0)
                                );
                              }, 0)} */}
                            <CreditDueComp companyId={x?._id} />
                          </TypographyComponent>

                          <TypographyComponent>
                            <RecoverBalanceComp companyId={x?._id} />
                          </TypographyComponent>

                          <TypographyComponent>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <IconButton aria-label="" style={iconStyle}>
                                <Delete
                                  onClick={() => {
                                    setIsDeleteModalOpen(true);
                                    setId(x._id);
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                aria-label="edit"
                                style={iconStyle}
                                onClick={() => {
                                  handleEditButtonClick(x._id);
                                  setId(x._id);
                                }}
                              >
                                <Edit />
                              </IconButton>
                              <Button
                                key={x._id}
                                variant="contained"
                                sx={{
                                  textTransform: "none",
                                  backgroundColor: "rgba(68, 95, 210, 1)",
                                  width: "auto",
                                  height: "22px",
                                  p: 1,
                                  boxShadow: "none",
                                  fontSize: "12px",
                                }}
                                onClick={() => handleButtonClick(x._id)}
                              >
                                Default
                              </Button>
                            </Box>
                          </TypographyComponent>
                        </TableRow>
                      );
                    })}
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}

          <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCloses}>
            <Box
              className="field-box-container"
              sx={{ py: 2, width: "100%", display: "flex", flexWrap: "wrap" }}
            >
              <Box
                className="field-box-full-width"
                sx={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <label>
                  <b>Enter Default Amount</b>
                </label>
                <input
                  type="Number"
                  name="productName"
                  value={defaultAmount}
                  min={0}
                  placeholder="Enter a default amount"
                  onChange={(e) => setDefaultAmount(e.target.value)}
                  style={{
                    width: "50%",
                    height: "30px",
                    border: `1px solid ${error ? "red" : "#e6e9ee"}`, // Change border color on error
                    borderRadius: "3px",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    padding: "5px",
                    fontSize: "11px",
                  }}
                />
                {error && (
                  <p style={{ color: "red", p: 0 }}>Please enter a value</p>
                )}
              </Box>
              <Box
                className="button-container"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  alignItems: "center",
                }}
              >
                <button className="cancel-button" onClick={handleCloses}>
                  Cancel
                </button>
                <button className="save-button" onClick={handleSave}>
                  Save
                </button>
              </Box>
            </Box>
          </Dialog>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              mx: "auto",
            }}
            mt={2}
          >
            <Stack>
              <StyledPagination
                size="medium"
                count={initialPaginationCount}
                color="primary"
                showFirstButton
                showLastButton
                page={currentPage}
                onChange={handlePageChange}
                strokeWidth={currentPage}
              />
            </Stack>
          </Box>
        </div>
      </Box>
      <Popup
        openPopup={isOpen}
        popupTitle="Edit : Product Details"
        popupContent={content}
        handleClose={() => {
          setIsOpen(false);
        }}
      />
      <CommonDelete
        open={isDeleteModalOpen}
        onClose={handleClose}
        onDelete={handleDelete}
        text="Are you sure you want to delete this Company?"
        ButtonLabel="Delete"
        cancelButtonLabel="Cancel"
      />
    </Paper>
  );
};

export default CompanyListing;
const iconStyle = {
  color: "rgba(68, 95, 210, 1)",
};

const TypoStyle = {
  width: "110px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#6B7A99",
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  // lineHeight: 20,
};

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
  minWidth: "150px",
  width: "auto",
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
  minWidth: "150px",
  width: "auto",
};

const TableContainerStyle = {
  minHeight: "500px",
  maxHeight: "500px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};

const TypographyComponent = ({ children }) => {
  return (
    <TableCell>
      <Typography sx={TextStyleCommon}>{children}</Typography>
    </TableCell>
  );
};
