import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "../Layouts/Layout.Module.css";

import GreyMarketplace from "../../assets/AdminDashboardImages/GreyMarketplace.png";
import GreyAdminHandles from "../../assets/AdminDashboardImages/GreyAdminHandles.svg";
import GreyAnalytics from "../../assets/AdminDashboardImages/GreyAnalytics.png";
import GreyChatQuery from "../../assets/AdminDashboardImages/GreyChatAndQuery.png";
import GreyGenie from "../../assets/AdminDashboardImages/GreyGenie.png";
import GreyMemberDirectory from "../../assets/AdminDashboardImages/GreyMemberDirectory.png";
import GreyRecycleBin from "../../assets/AdminDashboardImages/GreyRecyclebin.png";
import Greyuserandcompany from "../../assets/AdminDashboardImages/GreyUserandCompany.png";
import MarketPlace from "../../assets/AdminDashboardImages/MarketPlace.png";
import AdminHandles from "../../assets/AdminDashboardImages/AdminHandles.png";
import Analytics from "../../assets/AdminDashboardImages/Analytics.png";
import ChatQuery from "../../assets/AdminDashboardImages/ChatQuery.png";
import Genie from "../../assets/AdminDashboardImages/Genie.png";
import MemberDirectory from "../../assets/AdminDashboardImages/MemberDirectory.png";
import RecycleBin from "../../assets/AdminDashboardImages/RecycleBin.png";
import externalAPI from "../../assets/AdminDashboardImages/AllRecord.png";
import GreyExternalAPI from "../../assets/AdminDashboardImages/GreyExternalAPI.svg";
import Allrecords from "../../assets/AdminDashboardImages/Allrecords.svg";
import GreyAllrecords from "../../assets/AdminDashboardImages/GreyAllrecords.svg";
import userandcompany from "../../assets/AdminDashboardImages/userandcompany.png";

import CommunicationIcon from "../../assets/Communications/Communication.svg";
import GreyCommunicationIcon from "../../assets/Communications/GreyCommunication.svg";
import { useGetLoggedInUser } from "./Hooks";

import { Link, Outlet, useLocation } from "react-router-dom";
import AdminHeader from "./AdminHeader";
const drawerWidth = 250;

const SideBar = () => {
  const location = useLocation();
  const {
    data: CompanyData,
    isLoading: CompanyDataLoading,
    error: CompanyDataError,
  } = useGetLoggedInUser();

  const RouteArray = [
    {
      id: 1,
      name: "Admin Dashboard",
      match: "ADMINDASHBOARD",
      logoImage: AdminHandles,
      logoImageGrey: GreyAdminHandles,
      linkUrl: "userdashboard",
      textColor: "#7D8FB3",
      activeMenuFor: ["userdashboard", "banner"],
    },
    {
      id: 2,
      name: "Analytics",
      match: "ANALYTICS",
      logoImage: Analytics,
      logoImageGrey: GreyAnalytics,
      linkUrl: "analytics",
      textColor: "#7D8FB3",
      activeMenuFor: ["analytics"],
    },
    {
      id: 3,
      name: "Marketplace",
      match: "MARKETPLACE",
      logoImage: MarketPlace,
      logoImageGrey: GreyMarketplace,
      linkUrl: "marketplace",
      textColor: "#7D8FB3",
      activeMenuFor: ["marketplace"],
    },
    {
      id: 4,
      name: "Approvals",
      match: "APPROVALS",
      logoImage: MarketPlace,
      logoImageGrey: GreyMarketplace,
      linkUrl: "approvallisting",
      textColor: "#7D8FB3",
      activeMenuFor: ["approvallisting", "allproductapprovals"],
    },
    {
      id: 5,
      name: "Private Deal",
      match: "PRIVATEDEAL",
      logoImage: externalAPI,
      logoImageGrey: GreyExternalAPI,
      linkUrl: "addcouponcode",
      textColor: "#7D8FB3",
      activeMenuFor: ["addcouponcode"],
    },
    {
      id: 6,
      name: "Bulk Upload Approvals",
      match: "BULKUPLOAD",
      logoImage: externalAPI,
      logoImageGrey: GreyExternalAPI,
      linkUrl: "bulkuploadapproval",
      textColor: "#7D8FB3",
      activeMenuFor: ["bulkuploadapproval"],
    },
    {
      id: 7,
      name: "User & Company",
      match: "USERANDCOMPANY",
      logoImage: userandcompany,
      logoImageGrey: Greyuserandcompany,
      linkUrl: "companies",
      textColor: "#7D8FB3",
      activeMenuFor: ["companies", "companylanding"],
    },
    {
      id: 8,
      name: "Member Directory",
      match: "MEMBERDIRECTORY",
      logoImage: MemberDirectory,
      logoImageGrey: GreyMemberDirectory,
      linkUrl: "memberdirectory",
      textColor: "#7D8FB3",
      activeMenuFor: ["memberdirectory"],
    },
    {
      id: 9,
      name: "All Orders",
      match: "RELEASEFUNDS",
      logoImage: Allrecords,
      logoImageGrey: GreyAllrecords,
      linkUrl: "orders",
      textColor: "#7D8FB3",
      activeMenuFor: ["orders"],
    },
    {
      id: 10,
      name: "Chat & Query",
      match: "CHATANDQUERY",
      logoImage: ChatQuery,
      logoImageGrey: GreyChatQuery,
      linkUrl: "chat",
      textColor: "#7D8FB3",
      activeMenuFor: ["chat"],
    },
    {
      id: 11,
      name: "All Records",
      match: "ALLRECORDS",
      logoImage: Allrecords,
      logoImageGrey: GreyAllrecords,
      linkUrl: "allrecords",
      textColor: "#7D8FB3",
      activeMenuFor: [
        "allrecords",
        "invoices",
        "admindashboard/approvals",
        "interests",
        "messages",
        "delivery",
        "creditline",
        "wishlist",
      ],
    },
    {
      id: 12,
      name: "External API Reports",
      match: "EXTERNALAPIREPORTS",
      logoImage: externalAPI,
      logoImageGrey: GreyExternalAPI,
      linkUrl: "allexternalapi",
      textColor: "#7D8FB3",
      activeMenuFor: [
        "allexternalapi",
        "signzydata",
        "pinelabsdata",
        "escrowdata",
        "pickrrdata",
      ],
    },
    {
      id: 13,
      name: "Admin Handles",
      match: "ADMINHANDLES",
      logoImage: AdminHandles,
      logoImageGrey: GreyAdminHandles,
      linkUrl: "adminhandles",
      textColor: "#7D8FB3",
      activeMenuFor: ["adminhandles", "memberdetails"],
    },
    {
      id: 14,
      name: "Admin Trails",
      match: "ADMINTRAILS",
      logoImage: Allrecords,
      logoImageGrey: GreyAllrecords,
      linkUrl: "admintrails",
      textColor: "#7D8FB3",
      activeMenuFor: ["admintrails"],
    },
    {
      id: 15,
      name: "Genie",
      match: "GENIE",
      logoImage: Genie,
      logoImageGrey: GreyGenie,
      linkUrl: "genie",
      textColor: "#7D8FB3",
      activeMenuFor: ["genie"],
    },
    {
      id: 16,
      name: "Membership List",
      match: "MEMBERSHIPLIST",
      logoImage: externalAPI,
      logoImageGrey: GreyExternalAPI,
      linkUrl: "membershipinvoicelist",
      textColor: "#7D8FB3",
      activeMenuFor: ["membershipinvoicelist"],
    },
    {
      id: 17,
      name: "Commission List",
      match: "COMMISSIONLIST",
      logoImage: externalAPI,
      logoImageGrey: GreyExternalAPI,
      linkUrl: "commissioninvoicelist",
      textColor: "#7D8FB3",
      activeMenuFor: ["commissioninvoicelist"],
    },

    {
      id: 18,
      name: "Membership Amount Debit Status",
      match: "MEMBERSHIPAMOUNTDEBITSTATUS",
      logoImage: externalAPI,
      logoImageGrey: GreyExternalAPI,
      linkUrl: "memamountdebitstatus",
      textColor: "#7D8FB3",
      activeMenuFor: ["memamountdebitstatus"],
    },
    {
      id: 19,
      name: "Recycle Bin",
      match: "RECYLEBIN",
      logoImage: RecycleBin,
      logoImageGrey: GreyRecycleBin,
      linkUrl: "trash",
      textColor: "#7D8FB3",
      activeMenuFor: ["producttrash", "trash"],
    },

    {
      id: 20,
      name: "Communications",
      match: "COMMUNICATIONS",
      logoImage: CommunicationIcon,
      logoImageGrey: GreyCommunicationIcon,
      linkUrl: "communications",
      textColor: "#7D8FB3",
      activeMenuFor: ["communications"],
    },
    {
      id: 21,
      name: "TDS/GST",
      match: "TDS?GST",
      logoImage: CommunicationIcon,
      logoImageGrey: GreyCommunicationIcon,
      linkUrl: "tdsgst",
      textColor: "#7D8FB3",
      activeMenuFor: ["tdsgst"],
    },
  ];

  const filteredRoutes = [];
  if (CompanyData?.acess?.length === 0 || CompanyData?.acess?.at(0) === "All") {
    filteredRoutes.push(...RouteArray);
  } else {
    CompanyData?.acess?.forEach((item, idx) => {
      const itemName = item;

      const isMatch = RouteArray?.find((route) => route?.match === itemName);
      const isMatch2 = filteredRoutes?.some(
        (route) => route?.match === itemName
      );

      if (isMatch && !isMatch2) {
        filteredRoutes.push(isMatch);
      }
    });
  }

  filteredRoutes.push({
    id: 16,
    name: "Log Out",
    logoImage: userandcompany,
    logoImageGrey: Greyuserandcompany,
    linkUrl: "",
    onClick: () => {
      let confirm = window.confirm("Are you sure you want to logout?");
      if (confirm) {
        document.cookie =
          "BearerToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = "/login";
      } else {
        return;
      }
    },
    textColor: "#7D8FB3",
    activeMenuFor: ["logout"],
  });

  return (
    <div>
      <AdminHeader />
      <Box sx={{ display: "flex", overflowX: "hidden" }}>
        <CssBaseline />
        <Drawer
          PaperProps={{
            sx: {
              position: "inherit",
              borderRight: 0,
              width: drawerWidth,
              flexShrink: 0,
              height: "660px",
              // overflowY: "scroll",
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <List
            sx={{
              minHeight: "660px",
              maxHeight: "660px",
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "3px",
                display: "flex",
                borderRadius: "3px",
                maxHeight: "6px",
                minHeight: "6px",
                minWidth: "6px",
                bgcolor: "#e8eaf1",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#445fd2",
                borderRadius: "3px",
                cursor: "pointer",
              },
              "::-webkit-scrollbar-thumb:vertical": {
                maxWidth: "6px",
                minWidth: "6px",
              },
              "::-webkit-scrollbar-thumb:horizontal": {
                maxHeight: "6px",
                minHeight: "6px",
              },
            }}
          >
            {filteredRoutes?.map((res, index) =>
              res?.name === "Log Out" ? (
                <ListItem
                  className={
                    res?.activeMenuFor?.some((x) =>
                      location.pathname.includes(x)
                    )
                      ? "activebtn"
                      : res?.linkUrl === "null"
                      ? "disabled-link"
                      : ""
                  }
                  key={res?.name}
                  onClick={() => {
                    let confirm = window.confirm(
                      "Are you sure you want to logout?"
                    );
                    if (confirm) {
                      document.cookie =
                        "BearerToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                      window.location.href = "/login";
                    } else {
                      return;
                    }
                  }}
                  disablePadding
                  component={Link}
                  to={res?.linkUrl !== "null" ? res?.linkUrl : "#"}
                  style={{ marginTop: "8px" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img
                        src={
                          res?.activeMenuFor?.some((x) =>
                            location.pathname.includes(x)
                          )
                            ? res?.logoImageGrey
                            : res?.logoImage
                        }
                        width={"20px"}
                        height="20px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className="sidebartext"
                      sx={{ color: res?.textColor }}
                      primary={res?.name}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <ListItem
                  className={
                    res?.activeMenuFor?.some((x) =>
                      location.pathname.includes(x)
                    )
                      ? "activebtn"
                      : res?.linkUrl === "null"
                      ? "disabled-link"
                      : ""
                  }
                  key={res?.name}
                  disablePadding
                  component={Link}
                  to={res?.linkUrl !== "null" ? res?.linkUrl : "#"}
                  style={{ marginTop: "8px" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img
                        src={
                          res?.activeMenuFor?.some((x) =>
                            location.pathname.includes(x)
                          )
                            ? res?.logoImageGrey
                            : res?.logoImage
                        }
                        width={"20px"}
                        height="20px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      className="sidebartext"
                      sx={{ color: res?.textColor }}
                      primary={res?.name}
                    />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "660px",
            overflowY: "scroll",
            p: 3,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </div>
  );
};
export default SideBar;
