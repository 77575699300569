import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import {
  Tab,
  Box,
  Tabs,
  Modal,
  Paper,
  Button,
  Divider,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import "./Marketplace.Module.css";
// import { styled } from "@mui/system";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CloseIconImg from "../../assets/CloseIcon.svg";
import { GetTokenFunc } from "../../utils/GetToken";
import PageLoader from "../CommonActions/PageLoader";
import { DateRangePicker } from "mui-daterange-picker";
import { useDispatch, useSelector } from "react-redux";
import CommonDelete from "../CommonActions/CommonDelete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StyledPagination } from "../../common/StyledPagination";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { notifications } from "../../redux/action/Notification/notification";
import { GetAllProduct } from "../../redux/action/MarketPlace/Product-Filter";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MarketplaceEditReducer } from "../../redux/action/MarketPlace/Updatedata";

export let State = "";
export let Category = "";
export let Page = "";
export let TypeOfSelection = "";
export let PriceRevers = "";
export let ProductAnalysis = "";
export let Rating = "";
export let PriceRange = "";
export let SelectedCompany = "";
export let DateFilter = "";
export let ProductStatus = "";

const Marketplace = () => {
  const { products, loading } = useSelector((state) => state?.AllProducts);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [AllCompanyName, setAllCompanyName] = useState([]);
  const [category, setCategory] = useState("");
  const [priceRevers, setPriceRevers] = useState("");
  const [productAnalysis, setProductAnalysis] = useState("");
  const [productStatus, setProductStatus] = useState("");
  const [state, setState] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [activeTab, setActiveTab] = useState(3);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [date, setDate] = useState("");
  const [pid, setPid] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [showTextbox, setShowTextbox] = useState(false);
  const [VisiblePoints, setVisiblePoints] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [totalPoints, setTotalPoints] = useState({});
  const [sumOfPoints, setSumOfPoints] = useState(0);
  const [ProductData, setProductData] = useState();
  const [ProductVariationData, setProductVariationData] = useState();
  const [UpdateRequestProduct, setUpdateRequestProduct] = useState();
  const [creditDueBalance, setcreditDueBalance] = useState();
  const [dateArr, setDateArr] = useState([]);
  const [value, setValueState] = useState();
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
    setOpen(false);
    setVisiblePoints(false);
    setTotalPoints({});
    setSumOfPoints(0);
    setDateArr([]);
  };

  function Day(props) {
    const { day, selectedDay, TimelineData, ...other } = props;

    if (selectedDay == null) {
      return <PickersDay day={day} {...other} />;
    }

    const start = selectedDay;
    const end = start.add(ReturnDaysFromTimeline(TimelineData), "day");
    const dayIsBetween = day.isBetween(start, end, null, "[]");
    const isFirstDay = day.isSame(start, "day");
    const isLastDay = day.isSame(end, "day");

    return (
      <CustomPickersDay
        {...other}
        day={day}
        sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  }

  const countDaysfromTimeline = (value, timeline) => {
    if (timeline === "Week") {
      return value * 7;
    } else if (timeline === "Month") {
      return value * 30;
    } else if (timeline === "Year") {
      return value * 365;
    } else if (timeline === "Day") {
      return value;
    } else if (formData?.mediaVariation?.unit === "Spot") {
      return formData?.mediaVariation?.maxOrderQuantityunit;
    } else if (timeline === "One Time") {
      return value;
    }
  };

  function getDaysBetweenDates(startDate, endDate, Online) {
    if (Online) {
      const startDateAndTime = moment(startDate);
      const endDateAndTime = moment(endDate);
      // Add 1 day to include the end date in the result
      const daysDifference = endDateAndTime.diff(startDateAndTime, "days") + 1;
      return daysDifference;
    } else {
      var start = new Date(startDate);
      var end = new Date(endDate);
      // Calculate the time difference in milliseconds
      var timeDiff = end.getTime() - start.getTime();
      // Convert the time difference to days and add 1 to include both start and end dates
      var days = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) + 1;
      return days;
    }
  }

  function ReturnDaysFromTimeline(timeline) {
    if (timeline === "Day") {
      return 0;
    } else if (timeline === "Week") {
      return 6;
    } else if (timeline === "Month") {
      return 29;
    } else if (timeline === "Year") {
      return 364;
    }
  }

  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push({
        startDate: new Date(currentDate).toJSON(),
        endDate: new Date(currentDate).toJSON(),
        minOrderQuantityunit: Number(
          formData?.mediaVariation?.minOrderQuantityunit
        ),
        maxOrderQuantityunit: Number(
          formData?.mediaVariation?.maxOrderQuantityunit
        ),
        minTimeslotSeconds: Number(
          formData?.mediaVariation?.minTimeslotSeconds
        ),
        maxTimeslotSeconds: Number(
          formData?.mediaVariation?.maxTimeslotSeconds
        ),
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  function checkIfDateExists(dateArr, newStartDate, newEndDate) {
    for (let i = 0; i < dateArr.length; i++) {
      const { startDate, endDate } = dateArr[i];
      if (
        (newStartDate >= startDate && newStartDate <= endDate) ||
        (newEndDate >= startDate && newEndDate <= endDate)
      ) {
        return true;
      }
    }
    return false;
  }

  const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
      prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
  })(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    ...(isFirstDay && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...(isLastDay && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  }));

  const options = { day: "2-digit", month: "short", year: "numeric" };

  const handleInputChangeInput = (name, value) => {
    setSelectedOptions((prevOptions) => ({ ...prevOptions, [name]: value }));
  };

  useEffect(() => {
    // Calculate the total points based on the selected options
    const points = {};
    let sum = 0; // Initialize the sum

    for (const pointName in selectedOptions) {
      const selectedOption = selectedOptions[pointName];
      const point =
        formData?.productType === "Voucher"
          ? VoucherPoints.find((el) => el.name === pointName)
          : formData?.productType === "Product"
            ? ProductPoints.find((el) => el.name === pointName)
            : ProductPoints.find((el) => el.name === pointName);

      if (point && point.Points[selectedOption] !== undefined) {
        points[pointName] = point.Points[selectedOption];
        sum += point.Points[selectedOption]; // Add the point value to the sum
      }
    }

    setTotalPoints(points);
    setSumOfPoints(sum); // Set the sum
  }, [selectedOptions]);

  const getCreditDueBalance = async (data) => {
    await axios
      .get(`/api/v1/admin/wallet/get-wallet/${data?.SellerCompanyId?._id}`, {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setcreditDueBalance(res?.data?.body);
        const isAllOutOfStock = data?.ProductsVariantions.every(
          (variation) =>
            variation.MaxOrderQuantity < variation.MinOrderQuantity ||
            variation.MaxOrderQuantity === 0
        );
        if (
          res?.data?.body?.creditDue > 0 &&
          data?.ProductUploadStatus === "Approved" &&
          !isAllOutOfStock
        ) {
          toast.error("You can't delete this Ready Stock Product");
          return;
        } else {
          setIsDeleteModalOpen(true);
          setPid(data?._id);
        }
      })
      .catch((err) => { });
  };

  const GetProductCategory = async (
    name,
    id,
    type,
    listingType,
    VoucherType,
    ProductSubCategory
  ) => {
    if (listingType === "Voucher") {
      if (VoucherType === "Value Voucher / Gift Cards ") {
        navigate(`valueandgiftvoucher/${id}`);
      } else {
        navigate(`spacificvoucher/${id}`);
      }
    } else if (listingType === "Media") {
      navigate(`mediaviewproduct/${id}`, {
        state: { type: "Media" },
      });
    } else if (listingType === "Product") {
      navigate(`viewproduct/${id}`);
    } else {
      navigate(`viewproduct/${id}`);
    }
  };

  const GetProductUpdateRequest = async () => {
    await axios
      .get(`product/AllUpdateProductVariantionRequest`, {
        withCredentials: true,
      })
      .then((res) => {
        setUpdateRequestProduct(res?.data);
      })
      .catch((err) => { });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const handleDelete = async () => {
    // integrate the delete api here
    try {
      setIsDeleteModalOpen(false);
      const response = await axios.post(
        // `/AdminCompanyDetails/DeleteProductOfCompanys?ProductId=${pid}`
        `/api/v1/admin/DeleteProductOfCompanys?ProductId=${pid}`,
        {},
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      );

      // Assuming the deletion was successful, you can refetch the data here
      dispatch(GetAllProduct());
      // GetAllProduct();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleDeleteClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [formData, setFormData] = useState({
    productName: "",
    price: "",
    view: "",
    productType: "",
    location: "",
    listperiod: "",
    status: "",
    rejectionReason: "",
    reviewReason: "",
    reviewReasonNavigation: "",
    ProductsVariantions: [],
    ProductSubCategoryName: "",
    calender: dateArr,
    manualPoint: sumOfPoints,
    ProductCategoryName: "",
  });

  const SecondsFieldArr = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
    100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170,
    175, 180,
  ];

  function filterMultiples(array, multiple) {
    return array.filter(function (value) {
      return value % multiple === 0;
    });
  }

  const [MaxtimeslotArr, setMaxtimeslotArr] = useState([]);
  const [updateProductId, setUpdateProductId] = useState("");
  const [content, setContent] = useState("");
  const [ProductStatusForModal, setProductStatusForModal] = useState("");

  const inputRef = useRef(null);

  const { AllProductsDetailsByCompany } = useSelector(
    (state) => state?.ProductsDetails
  );

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const UpdateProduct = async () => {
    if (
      formData.status === "Review" &&
      (formData.reviewReason === undefined || formData.reviewReason === "")
    ) {
      toast.error("Please Fill the Review Reason field");
      return;
    }

    if (
      Object.keys(totalPoints).length < 7 &&
      formData?.status === "Approved" &&
      ProductData?.ManualPoint <= 0 &&
      formData?.productType !== "Media"
    ) {
      toast.error("Please Fill Manual Points");
      return;
    } else {
      if (
        deepEqual(formData?.ProductsVariantions, ProductVariationData) &&
        formData?.productType !== "Media"
      ) {
        // is matched
        let variationStatus = false;
        dispatch(
          MarketplaceEditReducer(
            updateProductId,
            formData,
            sumOfPoints,
            variationStatus
          )
        );
        dispatch(GetAllProduct());
        setIsOpen(false);
      } else if (
        deepEqual(formData?.ProductsVariantions, ProductVariationData) &&
        deepEqual(formData?.calender, dateArr) &&
        formData?.productType === "Media"
      ) {
        // is matched
        let variationStatus = false;
        dispatch(
          MarketplaceEditReducer(
            updateProductId,
            formData,
            sumOfPoints,
            variationStatus
          )
        );
        dispatch(GetAllProduct());
        setIsOpen(false);
      } else {
        // is not matched
        const formDataVariations = formData?.ProductsVariantions;
        const productVariationData = ProductVariationData;
        let allVariationsPassConditions = true;

        const MaxDaysTobeadded = countDaysfromTimeline(
          formData?.mediaVariation?.maxOrderQuantitytimeline,
          formData?.mediaVariation?.Timeline
        );

        let Totaldays = 0;
        if (
          formData?.ProductCategoryName === "MediaOffline" ||
          formData?.ProductCategoryName === "News Papers / Magazines"
        ) {
          dateArr.map((item) => {
            return (Totaldays += getDaysBetweenDates(
              item.startDate,
              item.endDate,
              false
            ));
          });
        } else {
          Totaldays =
            dateArr?.at(0)?.startDate === undefined
              ? 0
              : getDaysBetweenDates(
                dateArr?.at(0)?.startDate,
                dateArr?.at(-1)?.endDate,
                true
              );
        }

        for (let i = 0; i < formDataVariations.length; i++) {
          const currentVariation = formDataVariations[i];

          if (
            (currentVariation.DiscountedPrice >
              productVariationData[i]?.PricePerUnit ||
              currentVariation.DiscountedPrice >
              currentVariation?.PricePerUnit) &&
            formData?.productType === "Product"
          ) {
            toast.error(
              `DiscountedPrice of Variation ${i + 1
              } should not be greater than the PricePerUnit`
            );
            allVariationsPassConditions = false;
            break;
          } else if (
            currentVariation.TotalAvailableQty <
            currentVariation?.MaxOrderQuantity &&
            formData?.productType === "Voucher"
          ) {
            toast.error(
              `TotalAvailableQty of Variation ${i + 1
              } should not be less than the MaxOrderQuantity`
            );
            allVariationsPassConditions = false;
            break;
          } else if (
            (currentVariation.MaxOrderQuantity <
              productVariationData[i]?.MinOrderQuantity ||
              currentVariation.MaxOrderQuantity <
              currentVariation.MinOrderQuantity) &&
            formData?.productType !== "Media"
          ) {
            toast.error(
              `MaxOrderQuantity of Variation ${i + 1
              } should not be less than the MinOrderQuantity`
            );
            allVariationsPassConditions = false;
            break;
          } else if (
            (currentVariation.MaxOrderQuantity <
              productVariationData[i]?.MinOrderQuantity ||
              currentVariation.MaxOrderQuantity <
              currentVariation.MinOrderQuantity) &&
            formData?.ProductSubCategoryName !== "Airport Screens" &&
            formData?.productType === "Media"
          ) {
            toast.error(
              `MaxOrderQuantity of Variation ${i + 1
              } should not be less than the MinOrderQuantity`
            );
            allVariationsPassConditions = false;
            break;
          } else if (
            currentVariation.MaxOrderQuantity !==
            currentVariation.MinOrderQuantity &&
            formData?.ProductSubCategoryName === "Airport Screens" &&
            formData?.productType === "Media"
          ) {
            toast.error(
              `MaxOrderQuantity of Variation ${i + 1
              } should be same as the MinOrderQuantity`
            );
            allVariationsPassConditions = false;
            break;
          } else if (
            Number(currentVariation.maxTimeslotSeconds) <
            Number(currentVariation.minTimeslotSeconds) &&
            formData?.productType === "Media"
          ) {
            toast.error(
              `Max Timeslot Seconds of Variation ${i + 1
              } should not be less than the Min Timeslot Seconds`
            );
            allVariationsPassConditions = false;
            break;
          } else if (
            Totaldays < MaxDaysTobeadded &&
            formData?.productType === "Media" &&
            (formData?.ProductCategoryName === "MediaOffline" ||
              formData?.ProductCategoryName === "News Papers / Magazines")
          ) {
            toast.error(
              `Please Select Dates According to your timeline you need to add ${MaxDaysTobeadded} or more days and you have added ${Totaldays} days`
            );
            allVariationsPassConditions = false;
            break;
          } else if (
            !(Number(Totaldays) >= Number(MaxDaysTobeadded)) &&
            formData?.productType === "Media" &&
            (formData?.ProductCategoryName === "MediaOnline" ||
              formData?.ProductCategoryName === "Multiplex ADs")
          ) {
            toast.error(
              `Please Select Dates According to your timeline you need to add ${MaxDaysTobeadded} or more days and you have added ${Totaldays} days`
            );
            allVariationsPassConditions = false;
            break;
          }
        }

        if (allVariationsPassConditions) {
          let variationStatus = deepEqual(
            formData?.ProductsVariantions,
            ProductVariationData
          )
            ? false
            : true;
          let calenderStatus = formData?.calender
            ? deepEqual(formData?.calender, dateArr)
              ? false
              : true
            : false;
          dispatch(
            MarketplaceEditReducer(
              updateProductId,
              formData,
              sumOfPoints,
              variationStatus,
              calenderStatus,
              dateArr
            )
          );

          const AdminId = "650ac9c7b29820230639ab99";
          let receiver = ProductData?.SellerCompanyId?._id;
          let sender = AdminId;
          let message = `BXI Update Product Price And Quantity of ${ProductData?.ProductName}`;
          let type =
            formData?.productType === "Voucher" ? "Voucher" : "Product";
          dispatch(notifications(receiver, sender, message, type));
          dispatch(GetAllProduct());
          setIsOpen(false);
        }
      }
    }
  };

  // useEffect(() => {
  //   dispatch(MarketplaceEditReducer(id, currentPage));
  // }, [dispatch, selectedValue, currentPage, updateProductId]);

  useEffect(() => {
    if (inputRef.current !== null) {
      // Restore the cursor position after re-render
      inputRef.current.setSelectionRange(
        inputRef.current.selectionStart,
        inputRef.current.selectionEnd
      );
    }
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (value === "Rejected" || value === "Review") {
      setShowTextbox(true);
    } else {
      setShowTextbox(false);
    }
  };

  const handleVariationChange = (variationIndex, field, value) => {
    setFormData((prevFormData) => {
      const updatedVariations = [...prevFormData.ProductsVariantions];

      // Convert the value to a number using parseInt
      const numericValue = parseInt(value, 10);

      if (
        field === "maxTimeslotSeconds" ||
        field === "minTimeslotSeconds" ||
        field === "maxOrderQuantityunit" ||
        field === "minOrderQuantityunit"
      ) {
        updatedVariations[variationIndex] = {
          ...updatedVariations[variationIndex],
          [field]: value,
        };
      } else {
        updatedVariations[variationIndex] = {
          ...updatedVariations[variationIndex],
          [field]: numericValue,
        };
      }

      return {
        ...prevFormData,
        ProductsVariantions: updatedVariations,
      };
    });
  };
  const handleKeyDown = (e) => {
    // Allow numbers, decimal point, and navigation keys
    if (
      !/^[0-9.]$/.test(e.key) &&
      !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const ProductPoints = [
    {
      name: "If product is from BXI Star Category ",
      index: 2,
      bxiApprove: true,
      Points: {
        No: 2,
        Yes: 10,
      },
    },
    {
      name: "Products brand from the top category",
      index: 3,
      bxiApprove: true,
      Points: {
        "Not from the top 100 Brands": 0,
        "Its Brand Ranking is between 50 to 100": 4,
        "Brand Ranking is from >25 & < 50": 6,
        "Brand Ranking is from >5 & < 25": 8,
        "Yes, Top 5": 10,
      },
    },
    {
      name: "Product Price",
      index: 4,
      bxiApprove: true,
      Points: {
        "Higher Than Market Rate": 0,
        "As Per Market Operating Rates": 2,
        "Discounted to Market Rates but Higher then Bulk Rates": 6,
        "Institutional Bulk Purchase Discounted Rates": 10,
      },
    },
    {
      name: "Products Expiry Date / Shelf Life",
      index: 6,
      bxiApprove: true,
      Points: {
        "<= 25% life of the Product remaining": 0,
        "< 50% but >= 25 % Life of the Product Remainaing": 2,
        "< 75% but >= 50% Life of the Product Remainaing": 6,
        "< 100% but >= 75% Life of the Product Remainaing": 8,
        "100% Life of Product / Fresh Stock": 10,
      },
    },
    {
      name: "Product features and price with regards to its competitors",
      index: 7,
      bxiApprove: true,
      message: "Start Working After Analytics",
      Points: {
        "If product features are below their competitors": 0,
        "If product features are at par with their competitors": 4,
        "If product features are more compare to their competitors": 8,
        "If product has unique features & comapre to Competitors": 10,
      },
    },
    {
      name: "Product / SKU Demand in Marketplace",
      index: 8,
      bxiApprove: true,
      message: "Approve Why Product Is In Demand",
      Points: {
        "No Demand - Dead Stock": 0,
        "Low Demand": 2,
        "Medium Demand": 6,
        "High Demand": 10,
      },
    },
    {
      name: "Product Brand Standing",
      index: 11,
      bxiApprove: true,
      message: "Start Working After Analytics",
      Points: {
        "Single Retail Outlet in One City ( Eg: One outlet in Mumbai)": 0,
        "Multiple Retail Outlets in One City (Eg: Two or more outlets in Mumbai)": 2,
        "Multiple Retail Outlets in the One state (across couple of Cities) - Eg: Outlets in Mumbai | Pune | Nasik": 4,
        "Multiple Retail outlets in One Region (across multiple states in same region) Eg: West Region - Outlets in Maharashtra | Gujarat | Goa": 6,
        "Multiple Retail outlets in minimum Two Regions (across multiple states) Eg: East (Kolkata | Sikkim & West ( Maharashtra & Gujarat)": 8,
        "National (Multiples Retail Outles across all 4 Regions)": 10,
      },
    },
  ];

  const VoucherPoints = [
    {
      name: "Vouchers from BXI Star Category",
      index: 2,
      bxiApprove: true,
      Points: {
        No: 2,
        Yes: 10,
      },
    },
    {
      name: "Reedemable Criteria",
      index: 4,
      bxiApprove: true,
      Points: {
        "Specific on single product/Service": 0,
        "Specific on Multiple Products/Services.": 2,
        "Specific on more than one Brand & its Products/Services.": 6,
        "Fully Redeemable": 10,
      },
    },
    {
      name: "Clubbable Criteria",
      index: 5,
      bxiApprove: true,
      Points: {
        "Non Clubbable": 6,
        "Fully Clubbable": 10,
      },
    },
    {
      name: "Voucher Acceptability (Online and Offline)",
      index: 6,
      bxiApprove: true,
      Points: {
        "Single Store/ Locations": 2,
        "Selected Stores| Locations/All products": 6,
        "All products/Services All store & Locations": 10,
      },
    },
    {
      name: "Voucher category demand in marketplace",
      index: 7,
      bxiApprove: true,
      Points: {
        "No Demand": 0,
        "Low Demand": 2,
        "Medium Demand": 6,
        "High Demand": 10,
      },
    },
    {
      name: "Voucher Validity",
      index: 9,
      bxiApprove: true,
      Points: {
        "Less than 3 months": 0,
        "> 3 Months & < = 6 months": 2,
        "> 6 months & <= 12 Months": 6,
        "> 12 Months": 10,
      },
    },
    {
      name: "Voucher Product Brand Standing",
      index: 10,
      bxiApprove: true,
      Points: {
        "Single Retail Outlet in One City ( Eg: One outlet in Mumbai)": 0,
        "Multiple Retail Outlets in One City (Eg: Two or more outlets in Mumbai)": 2,
        "Multiple Retail Outlets in the One state (across couple of Cities) - Eg: Outlets in Mumbai | Pune | Nasik": 4,
        "Multiple Retail outlets in One Region (across multiple states in same region) Eg: West Region - Outlets in Maharashtra | Gujarat | Goa": 6,
        "Multiple Retail outlets in minimum Two Regions (across multiple states) Eg: East (Kolkata | Sikkim & West ( Maharashtra & Gujarat)": 8,
        "National (Multiples Retail Outles across all 4 Regions)": 10,
      },
    },
    {
      name: "Voucher Product & Company Demand Check",
      index: 11,
      bxiApprove: true,
      Points: {
        "Both Not in Demand": 0,
        "Company in Demand / Product Not in Demand": 4,
        "Product in Demand / Company Not in Demand": 8,
        "Both Product & Company in Demand": 10,
      },
    },
  ];

  const setDataEditButton = (productId) => {
    const productToEdit = products?.products?.find(
      (product) => product?._id === productId
    );
    setProductStatusForModal(productToEdit?.ProductUploadStatus);
    setProductData(productToEdit);

    setUpdateProductId(productId);

    if (productToEdit) {
      setFormData({
        productName: productToEdit?.ProductName,
        ProductsVariantions: productToEdit?.ProductsVariantions,
        mediaVariation: productToEdit?.mediaVariation,
        price: productToEdit?.ProductsVariantions[0]?.DiscountedPrice,
        listperiod:
          productToEdit?.ListingType === "Product" ||
            productToEdit?.ListingType === "Media"
            ? productToEdit?.listperiod
            : productToEdit?.ListingType === "Voucher"
              ? productToEdit?.ListThisProductForAmount
              : null,
        view: productToEdit?.ProductAnalysis?.ProductViewCount,
        productType: productToEdit?.ListingType,
        location: productToEdit?.LocationDetails?.city,
        status: productToEdit?.ProductUploadStatus,
        rejectionReason: productToEdit?.rejectionReason,
        reviewReason: productToEdit?.reviewReason,
        reviewReasonNavigation: productToEdit?.reviewReasonNavigation,
        manualPoint: sumOfPoints,
        ProductSubCategoryName: productToEdit?.ProductSubCategoryName,
        ProductSubCategory: productToEdit?.ProductSubCategory,
        calender: productToEdit?.calender,
        ProductCategoryName:
          productToEdit?.ListingType === "Media"
            ? productToEdit?.ProductCategoryName
            : "",
      });
      setDateArr(productToEdit?.calender);
      setProductVariationData(productToEdit?.ProductsVariantions);
    }
  };

  useEffect(() => {
    if (products && products?.count !== undefined) {
      setInitialPaginationCount(products?.count);
    }
  }, [products?.count]);

  Page = currentPage;
  Category = category;
  TypeOfSelection = activeTab;
  PriceRevers = priceRevers;
  ProductAnalysis = productAnalysis;
  State = state;
  PriceRange = priceRange;
  SelectedCompany = selectedCompany;
  DateFilter = date;
  ProductStatus = productStatus;

  const filterByCompany = async () => {
    await axios
      .get(`product/get_allproducts_bycompany`, { withCredentials: true })
      .then((res) => {
        setAllCompanyName(res.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    filterByCompany();
    GetProductUpdateRequest();
  }, []);

  useEffect(() => {
    dispatch(GetAllProduct());
  }, [
    dispatch,
    category,
    currentPage,
    activeTab,
    priceRevers,
    productAnalysis,
    state,
    priceRange,
    selectedCompany,
    date,
    productStatus,
  ]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleCompanyChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCompany(selectedValue);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box className="page-header-dropwon-section">
        <Box className="marketplace-filters">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <label style={{ width: "100px" }}>Select Category</label>

            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              style={{
                height: "40px",
              }}
            >
              <option value="">All</option>
              <option value="63e38bddcc4c02b8a0c94b8a">Others</option>
              <option value="63e38b9ccc4c02b8a0c94b6f">Lifestyle</option>
              <option value="63e38bb9cc4c02b8a0c94b7b">FMCG</option>
              <option value="63e38b91cc4c02b8a0c94b69">Media</option>
              <option value="63e38ba3cc4c02b8a0c94b72">Hotel</option>
              <option value="63e38bbfcc4c02b8a0c94b7e">Airlines Tickets</option>
              <option value="63e38bc6cc4c02b8a0c94b81">Office Supply</option>
              <option value="63e38bb3cc4c02b8a0c94b78">Mobility</option>
              <option value="63e38bcecc4c02b8a0c94b84">Electronics</option>
              <option value="63e38b96cc4c02b8a0c94b6c">Textile</option>
              <option value="64218b189fe1b6ae750c11bd">
                Entertainment & Events
              </option>
              <option value="63e38bd5cc4c02b8a0c94b87">QSR</option>
            </select>
          </Box>
        </Box>
        <Box className="marketplace-filters">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <label style={{ width: "100px" }}>Select Company</label>
            <select onChange={handleCompanyChange} value={selectedCompany}>
              <option value="">All</option>
              {AllCompanyName?.map((name, index) => (
                <option key={index} value={name?.companyName}>
                  {name?.companyName}
                </option>
              ))}
            </select>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        mt={2}
        className="page-heading-section"
        style={{ justifyContent: "start", gap: "20px" }}
      >
        <Box className="page-heading">
          <h5>Explore & Approve Listed Products Here</h5>
          <Typography>Check Pcitures | Meta | Price | Details</Typography>
        </Box>

        <Tabs
          sx={{
            marginLeft: "350px",
          }}
          className="page-filters-tabs"
          value={activeTab}
          onChange={handleTabChange}
          centered
        >
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Products"
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Vouchers"
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Media"
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="All"
          />
        </Tabs>
      </Box>
      <Box className="marketplace-filters">
        <Box>
          <label>Price</label>
          <select
            value={priceRevers}
            onChange={(e) => setPriceRevers(e.target.value)}
          >
            <option value="">All</option>
            <option value="LowToHigh">Low to High</option>
            <option value="HighToLow">High to Low</option>
          </select>
        </Box>
        <Box>
          <label>Type</label>
          <select
            value={productAnalysis}
            onChange={(e) => setProductAnalysis(e.target.value)}
          >
            <option value="">All</option>
            <option value="MostViewed">Most Viewed</option>
            <option value="MostAddToCard">Most Add to Card</option>
            <option value="MostBuyed">Most Buyed</option>
          </select>
        </Box>
        <Box>
          <label>Location</label>
          <select value={state} onChange={(e) => setState(e.target.value)}>
            <option value="">All</option>
            <option value="AndhraPradesh">Andhra Pradesh</option>
            <option value="ArunachalPradesh">Arunachal Pradesh</option>
            <option value="Assam">Assam</option>
            <option value="Bihar">Bihar</option>
            <option value="Chhattisgarh">Chhattisgarh</option>
            <option value="Goa">Goa</option>
            <option value="Gujarat">Gujarat</option>
            <option value="Haryana">Haryana</option>
            <option value="HimachalPradesh">Himachal Pradesh</option>
            <option value="Jammu&Kashmir">Jammu & Kashmir</option>
            <option value="Jharkhand">Jharkhand</option>
            <option value="Karnataka">Karnataka</option>
            <option value="Kerala">Kerala</option>
            <option value="MadhyaPradesh">Madhya Pradesh</option>
            <option value="Maharashtra">Maharashtra</option>
            <option value="Manipur">Manipur</option>
            <option value="Meghalaya">Meghalaya</option>
            <option value="Mizoram">Mizoram</option>
            <option value="Nagaland">Nagaland</option>
            <option value="Odisha">Odisha</option>
            <option value="Punjab">Punjab</option>
            <option value="Rajasthan">Rajasthan</option>
            <option value="Sikkim">Sikkim</option>
            <option value="TamilNadu">Tamil Nadu</option>
            <option value="Telangana">Telangana</option>
            <option value="Tripura">Tripura</option>
            <option value="UttarPradesh">Uttar Pradesh</option>
            <option value="Uttarakhand">Uttarakhand</option>
            <option value="WestBengal">West Bengal</option>
          </select>
        </Box>
        <Box>
          <label>Days</label>
          <div class="input-container">
            <select value={date} onChange={(e) => setDate(e.target.value)}>
              <option value="">All</option>
              <option value="7">7 Days</option>
              <option value="15">15 Days</option>
              <option value="30">1 Month</option>
              <option value="90">3 Month</option>
              <option value="180">6 Month</option>
              <option value="360">1 Year</option>
              <option value="1080">3 Year</option>
              <option value="1800">5 Year</option>
            </select>
          </div>
        </Box>
        <Box>
          <label> Status</label>
          <select
            value={productStatus}
            onChange={(e) => setProductStatus(e.target.value)}
          >
            <option value="">All</option>
            {/* <option value="pending">Pending</option> */}
            <option value="Approved">Approved</option>
            <option value="pendingapproval">Pending Approval</option>
            <option value="productinformation">Draft</option>
            <option value="Delist">Delist</option>
            <option value="Review">Review</option>
            <option value="Rejected">Rejected</option>
          </select>
        </Box>
        <Box>
          <label>Price Range</label>
          <select
            value={priceRange}
            onChange={(e) => setPriceRange(e.target.value)}
          >
            <option value="">All</option>
            <option value="50000">50,000</option>
            <option value="100000">1,00,000</option>
            <option value="200000">2,00,000</option>
            <option value="300000">3,00,000</option>
            <option value="400000">4,00,000</option>
            <option value="500000">5,00,000</option>
            <option value="600000">6,00,000</option>
          </select>
        </Box>{" "}
      </Box>
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2, mt: 1 }}>
        <Box className="products-filters-row">
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              Total Products : {products?.totalProductCount}
            </Typography>
          </Box>
        </Box>
        <div>
          {products?.products?.length <= 0 ? (
            <Box sx={setBox}>
              <img
                src={recyclebin}
                alt="recycle"
                style={{ height: "auto", width: "23%" }}
              />
              <Typography sx={empty}>Data is empty</Typography>
            </Box>
          ) : (
            <table className="common-table">
              <thead>
                <tr>
                  <td width={"4%"} align="left">
                    No .
                  </td>
                  <td width={"20%"} align="left">
                    Product Name
                  </td>
                  <td width={"20%"}>Member</td>
                  <td width={"10%"}>Price</td>
                  <td width={"10%"}>
                    {productAnalysis === ""
                      ? "View"
                      : productAnalysis === "MostViewed"
                        ? "Most View"
                        : productAnalysis === "MostAddToCard"
                          ? "Add to Cart"
                          : productAnalysis === "MostBuyed"
                            ? "Most Buy"
                            : null}
                  </td>
                  <td width={"8%"}>Available Quantity</td>
                  <td
                    width={"10%"}
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Product Type
                  </td>
                  <td width={"10%"}>Status</td>
                  {/* <td width={"10%"}>Credit Status</td> */}

                  <td width={"10%"}>Date</td>
                  <td width={"10%"}>User update status</td>
                  <td width={"10%"}>Location</td>
                  <td width={"10%"}>Action</td>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 5,
                      width: "100%",
                      // mr: '150%',
                      ml: "500px",
                    }}
                  >
                    <PageLoader />
                  </Box>
                ) : (
                  <React.Fragment>
                    {products?.products?.map((x, i) => {
                      let displayValue = ""; // Define displayValue here based on your logic
                      if (productAnalysis === "") {
                        displayValue =
                          x?.ProductAnalysis?.ProductViewCount || 0;
                      } else if (productAnalysis === "MostViewed") {
                        displayValue =
                          x?.ProductAnalysis?.ProductViewCount || 0;
                      } else if (productAnalysis === "MostAddToCard") {
                        displayValue =
                          x?.ProductAnalysis?.ProductAddToCardCount || 0;
                      } else if (productAnalysis === "MostBuyed") {
                        displayValue =
                          x?.ProductAnalysis?.ProductPurchaseCount || 0;
                      }
                      const UpdateData = UpdateRequestProduct?.filter(
                        (product) => product?.productId === x?._id
                      ).sort(
                        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
                      );

                      const latestUpdate = UpdateData?.at(0);
                      return (
                        <tr>
                          <td className="font-10-600">
                            <span>{i + 1}</span>
                          </td>
                          <td align="left" className="product-title-colum">
                            <Box
                              sx={{
                                border: "1px solid #929EAE",
                                p: 0.2,
                                display: "flex",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              <img
                                src={
                                  x?.ProductImages?.at(0)?.url ||
                                  x?.VoucherImages?.at(0)?.url
                                }
                                alt="imgs"
                                style={{
                                  height: "30px",
                                  width: "30px",
                                }}
                              />
                            </Box>
                            {/* {x?.ProductImages?.at(0)?._id} */}
                            <Box className="product-title-box">
                              <label
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  maxWidth: "150px",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {x?.ProductName}
                              </label>
                              <span>
                                {" "}
                                Category Name :{" "}
                                {x?.ProductCategoryName
                                  ? x?.ProductCategoryName
                                  : x?.VoucherType}
                              </span>
                            </Box>
                          </td>
                          <td
                            className="font-10-600"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {x?.SellerCompanyName}
                          </td>

                          <td className="font-10-600">
                            {x?.ProductsVariantions[0]?.DiscountedPrice
                              ? `₹ ${x?.ProductsVariantions[0]?.DiscountedPrice}`
                              : "--"}
                          </td>
                          <td className="font-10-600">
                            {displayValue || "--"}
                          </td>
                          <td className="font-10-600">
                            {x?.ProductsVariantions[0]?.MaxOrderQuantity ||
                              "--"}
                          </td>

                          <td className="font-10-600">
                            {x?.ListingType ? x?.ListingType : "Product"}
                          </td>
                          <td>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "110px",
                                height: "35px",
                                borderRadius: 2,
                                background:
                                  x?.ProductUploadStatus === "Approved"
                                    ? "#D9FFE9"
                                    : x?.ProductUploadStatus ===
                                      "pendingapproval" ||
                                      x?.ProductUploadStatus ===
                                      "productinformation" ||
                                      x?.ProductUploadStatus === "Review" ||
                                      x?.ProductUploadStatus === "Draft"
                                      ? "#FFF1E5"
                                      : x?.ProductUploadStatus === "Rejected" ||
                                        x?.ProductUploadStatus === "Expiry"
                                        ? "#FFEFEF"
                                        : "#FFEFEF",
                              }}
                            >
                              <Typography
                                sx={{
                                  color:
                                    x?.ProductUploadStatus === "Approved"
                                      ? "#27AE60"
                                      : x?.ProductUploadStatus ===
                                        "pendingapproval" ||
                                        x?.ProductUploadStatus ===
                                        "productinformation" ||
                                        x?.ProductUploadStatus === "Review" ||
                                        x?.ProductUploadStatus === "Draft"
                                        ? "#F2994A"
                                        : x?.ProductUploadStatus === "Rejected" ||
                                          x?.ProductUploadStatus === "Expiry" ||
                                          x?.ProductUploadStatus === "Delist"
                                          ? "#EB5757"
                                          : "#EB5757",
                                  width: "auto",
                                  height: "auto",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                {x?.ProductUploadStatus === "Approved"
                                  ? "Approved"
                                  : x?.ProductUploadStatus === "Review"
                                    ? "Review"
                                    : x?.ProductUploadStatus === "pendingapproval"
                                      ? "Pending Approval"
                                      : x?.ProductUploadStatus ===
                                        "productinformation"
                                        ? "In Draft"
                                        : x?.ProductUploadStatus === "Rejected"
                                          ? "Rejected"
                                          : x?.ProductUploadStatus === "Delist"
                                            ? "Delist"
                                            : x?.ProductUploadStatus === "Expiry"
                                              ? "Expiry"
                                              : x?.ProductUploadStatus === null
                                                ? null
                                                : "In Draft"}
                              </Typography>
                            </Box>
                          </td>
                          <td className="font-10-500">
                            {" "}
                            {x?.createdAt && formatDate(x.createdAt)}
                          </td>
                          <td className="font-10-500">
                            {latestUpdate === null ||
                              latestUpdate === undefined ||
                              Object.keys(latestUpdate).length === 0 ? (
                              "--"
                            ) : (
                              <span>
                                {latestUpdate?.userRequestStatus === "Accpeted"
                                  ? "Accepted"
                                  : latestUpdate?.userRequestStatus}
                              </span>
                            )}
                          </td>
                          <td className="font-10-500">
                            {x?.LocationDetails?.city?.length ||
                              x?.GeographicalData?.city?.length > 0 ? (
                              <>
                                {x?.LocationDetails?.city}
                                {x?.GeographicalData?.city}
                              </>
                            ) : (
                              "Not Mention"
                            )}
                          </td>

                          <td
                            style={{
                              whiteSpace: "nowrap",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <IconButton aria-label="delete" style={iconStyle}>
                              <Delete
                                onClick={() => {
                                  getCreditDueBalance(x);
                                }}
                              />
                            </IconButton>

                            {x?.ProductUploadStatus === "productinformation" ? (
                              <p>
                                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                              </p>
                            ) : (
                              <>
                                <IconButton
                                  aria-label="edit"
                                  style={iconStyle}
                                  onClick={() => {
                                    setDataEditButton(x?._id);
                                    if (
                                      x?.ProductsVariantions?.at(0)
                                        ?.minTimeslotSeconds
                                    ) {
                                      setMaxtimeslotArr(
                                        filterMultiples(
                                          SecondsFieldArr,
                                          x?.ProductsVariantions?.at(0)
                                            ?.minTimeslotSeconds
                                        )
                                      );
                                    }
                                    setIsOpen(true);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </>
                            )}

                            <IconButton
                              aria-label="view"
                              style={iconStyle}
                              onClick={() => {
                                GetProductCategory(
                                  x?.ProductCategoryName,
                                  x?._id,
                                  x?.ProductType,
                                  x?.ListingType,
                                  x?.VoucherType,
                                  x?.ProductSubCategory
                                );
                              }}
                            >
                              <VisibilityIcon sx={{ color: "#445FD2" }} />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                )}
              </tbody>
            </table>
          )}
        </div>
      </Box>

      <Dialog
        open={isOpen}
        className="custom-popup"
        onClose={handleClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "50%",
            borderRadius: "13px",
            color: "#6B7A99",
          },
        }}
      >
        <DialogTitle>
          Edit : Product Details
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 25,
              top: 8,
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ p: 2, display: "flex", justifyContent: "ceneter" }}
        >
          <Box className="field-box-container" sx={{ p: 0 }}>
            <Box className="field-box" sx={{}}>
              <label>Product Name</label>
              <input
                type="text"
                name="productName"
                value={formData?.productName}
                placeholder="Sell Your Products/ Services"
                style={{ width: "98%" }}
                onChange={handleInputChange}
                ref={inputRef} // Set a ref to the input element
              />
            </Box>

            <Box className="field-box">
              <label>Product Listing Type</label>
              <input
                type="text"
                name="productType"
                style={{
                  width: "92%",
                  height: "40px",
                  border: "1px solid #e8e8e8",
                  borderRadius: 2,
                  padding: "0 10px",
                  webkitAppearance: "none",
                }}
                disabled={true}
                value={formData?.productType}
              />
            </Box>
            {formData?.ProductsVariantions?.map((el, idx) => {
              return (
                <Box
                  sx={{
                    borderBottom: "2px solid rgb(107 122 153 / 19%)",
                    width: "100%",
                    pb: 1,
                    mt: 1,
                  }}
                >
                  {formData?.productType === "Media" ? null : (
                    <Box
                      sx={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "5px",
                        backgroundColor: el?.ProductColor,
                        cursor: "pointer",
                        border: "1px solid black",
                        "&:hover": {
                          boxShadow: "3px 3px 3px grey",
                          border: "1px solid blue",
                        },
                      }}
                    ></Box>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: 5,
                    }}
                  >
                    {formData?.productType === "Voucher" ? null : (
                      <Box
                        sx={{
                          width: "50%",
                        }}
                      >
                        <label style={FieldBoxLabel}>Discounted Price</label>

                        <br />
                        <input
                          type="number"
                          name="price"
                          value={el?.DiscountedPrice}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Check if the input is valid (number or empty)
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleVariationChange(
                                idx,
                                "DiscountedPrice",
                                value
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            handleKeyDown(e);
                          }}
                          style={FieldBoxInput}
                          min="0"
                        />
                      </Box>
                    )}
                    {formData?.productType === "Media" ? null : (
                      <Box
                        sx={{
                          width: "50%",
                        }}
                      >
                        <label style={FieldBoxLabel}>Price Per Unit</label>
                        <br />

                        <input
                          type="number"
                          name="PricePerUnit"
                          value={el?.PricePerUnit}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (formData?.productType === "Voucher") {
                              if (/^\d*\.?\d*$/.test(value)) {
                                handleVariationChange(
                                  idx,
                                  "DiscountedPrice",
                                  value
                                );
                              }
                            }
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleVariationChange(idx, "PricePerUnit", value);
                            }
                          }}
                          onKeyDown={(e) => {
                            handleKeyDown(e);
                          }}
                          style={FieldBoxInput}
                          min="0"
                        />
                      </Box>
                    )}
                    {formData?.productType === "Media" ? (
                      <Box
                        sx={{
                          width: "50%",
                        }}
                      >
                        <label style={FieldBoxLabel}>Price Per Unit</label>
                        <br />

                        <input
                          type="number"
                          name="PricePerUnit"
                          value={el?.PricePerUnit}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleVariationChange(idx, "PricePerUnit", value);
                            }
                          }}
                          style={FieldBoxInput}
                          onKeyDown={(e) => {
                            handleKeyDown(e);
                          }}
                          min="0"
                        />
                      </Box>
                    ) : null}

                    {formData?.productType === "Voucher" ? (
                      <Box
                        sx={{
                          width: "50%",
                        }}
                      >
                        <label style={FieldBoxLabel}>Total QTY</label>
                        <br />

                        <input
                          type="number"
                          name="TotalAvailableQty"
                          value={el?.TotalAvailableQty}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleVariationChange(
                                idx,
                                "TotalAvailableQty",
                                value
                              );
                            }
                          }}
                          style={FieldBoxInput}
                          onKeyDown={(e) => {
                            handleKeyDown(e);
                          }}
                          min="0"
                        />
                      </Box>
                    ) : null}
                  </Box>

                  {formData?.productType === "Media" &&
                    formData?.ProductSubCategoryName ===
                    "News Papers / Magazines" ? null : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: 5,
                      }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <label style={FieldBoxLabel}>
                          Min Quantity{" "}
                          {formData?.productType === "Media" ? "/ Unit" : ""}
                        </label>
                        <br />

                        <input
                          type="number"
                          name="MinQuantity"
                          value={el?.MinOrderQuantity}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleVariationChange(
                                idx,
                                "MinOrderQuantity",
                                value
                              );
                            }
                            if (formData?.productType === "Media") {
                              if (/^\d*\.?\d*$/.test(value)) {
                                handleVariationChange(
                                  idx,
                                  "minOrderQuantityunit",
                                  value
                                );
                              }
                            }
                          }}
                          style={FieldBoxInput}
                          onKeyDown={(e) => {
                            handleKeyDown(e);
                          }}
                          min="0"
                        />
                      </Box>

                      <Box sx={{ width: "50%" }}>
                        <label style={FieldBoxLabel}>
                          Max Quantity{" "}
                          {formData?.productType === "Media" ? "/ Unit" : ""}
                        </label>
                        <br />

                        <input
                          type="number"
                          name="MaxQuantity"
                          value={el?.MaxOrderQuantity}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleVariationChange(
                                idx,
                                "MaxOrderQuantity",
                                value
                              );
                            }
                            if (formData?.productType === "Media") {
                              if (/^\d*\.?\d*$/.test(value)) {
                                handleVariationChange(
                                  idx,
                                  "maxOrderQuantityunit",
                                  value
                                );
                              }
                            }
                          }}
                          style={FieldBoxInput}
                          onKeyDown={(e) => {
                            handleKeyDown(e);
                          }}
                          min="0"
                        />
                      </Box>
                    </Box>
                  )}
                  {formData?.productType === "Media" &&
                    el?.minTimeslotSeconds ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: 5,
                      }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <label style={FieldBoxLabel}>
                          Min Timeslot Seconds
                        </label>
                        <br />
                        <select
                          style={{
                            width: "92%",
                            height: "40px",
                            border: "1px solid #e8e8e8",
                            borderRadius: 2,
                            padding: "0 10px",
                            webkitAppearance: "none",
                          }}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            handleVariationChange(
                              idx,
                              "minTimeslotSeconds",
                              selectedValue
                            );
                            setMaxtimeslotArr(
                              filterMultiples(SecondsFieldArr, selectedValue)
                            );
                          }}
                          defaultValue={el?.minTimeslotSeconds}
                        >
                          {SecondsFieldArr?.map((item, idx) => (
                            <option value={item} key={idx}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </Box>

                      <Box sx={{ width: "50%" }}>
                        <label style={FieldBoxLabel}>
                          Max Timeslot Seconds
                        </label>
                        <br />

                        <select
                          style={{
                            width: "92%",
                            height: "40px",
                            border: "1px solid #e8e8e8",
                            borderRadius: 2,
                            padding: "0 10px",
                            webkitAppearance: "none",
                          }}
                          onChange={(e) => {
                            handleVariationChange(
                              idx,
                              "maxTimeslotSeconds",
                              e.target.value
                            );
                          }}
                          defaultValue={el?.maxTimeslotSeconds}
                        >
                          {MaxtimeslotArr.length <= 0 ? (
                            <option
                              value={el?.maxTimeslotSeconds}
                              key={el?.maxTimeslotSeconds}
                            >
                              {el?.maxTimeslotSeconds}
                            </option>
                          ) : (
                            MaxtimeslotArr?.map((item, idx) => {
                              const minTimeslotSeconds = Number(
                                el?.minTimeslotSeconds
                              );

                              if (minTimeslotSeconds >= Number(item)) {
                                return null;
                              }

                              return (
                                <option value={item} key={idx}>
                                  {item}
                                </option>
                              );
                            })
                          )}
                        </select>
                      </Box>
                    </Box>
                  ) : null}
                  {formData?.productType === "Media" &&

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: 5,
                      }}
                    >
                      {/* {el?.minOrderQuantitytimeline && ( */}
                      <Box sx={{ width: "50%" }}>
                        <label style={FieldBoxLabel}>
                          Min Order QTY Timeline ({el?.Timeline})
                        </label>
                        <br />

                        <input
                          type="number"
                          name="MinQuantity"
                          value={el?.minOrderQuantitytimeline}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleVariationChange(
                                idx,
                                "minOrderQuantitytimeline",
                                value
                              );
                            }
                          }}
                          style={FieldBoxInput}
                          onKeyDown={(e) => {
                            handleKeyDown(e);
                          }}
                          min="0"
                        />
                      </Box>
                      {/* )} */}

                      {/* {el?.maxOrderQuantitytimeline && ( */}
                      <Box sx={{ width: "50%" }}>
                        <label style={FieldBoxLabel}>
                          Max Order QTY Timeline ({el?.Timeline})
                        </label>
                        <br />

                        <input
                          type="number"
                          name="MaxQuantity"
                          value={el?.maxOrderQuantitytimeline}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleVariationChange(
                                idx,
                                "maxOrderQuantitytimeline",
                                value
                              );
                            }
                          }}
                          style={FieldBoxInput}
                          onKeyDown={(e) => {
                            handleKeyDown(e);
                          }}
                          min="0"
                        />
                      </Box>
                      {/* )} */}
                    </Box>
                  }
                </Box>
              );
            })}

            {formData?.productType === "Media" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      ...CommonTextStyle,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Calendar ( Select availability of Media){" "}
                    <CalendarMonthIcon
                      onClick={handleToggle}
                      sx={{
                        height: "30px",
                        width: "30px",
                        cursor: "pointer",
                      }}
                    />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: open ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {formData?.mediaVariation?.Timeline === "Day" ||
                    formData?.mediaVariation?.Timeline === "One Time" ? (
                    <DateRangePicker
                      minDate={new Date()}
                      disablePast={true}
                      calender={1}
                      definedRanges={[]}
                      open={true}
                      toggle={toggle}
                      onChange={(range) => {
                        let isRangeValid = true;
                        for (const existingRange of dateArr) {
                          const { startDate, endDate } = existingRange;
                          if (
                            (new Date(range.startDate).toJSON() >=
                              new Date(startDate).toJSON() &&
                              new Date(range.startDate).toJSON() <=
                              new Date(endDate).toJSON()) ||
                            (new Date(range.endDate).toJSON() >=
                              new Date(startDate).toJSON() &&
                              new Date(range.endDate).toJSON() <=
                              new Date(endDate).toJSON())
                          ) {
                            isRangeValid = false;
                            break;
                          }
                        }
                        let DayDateArr = getDates(
                          range.startDate,
                          range.endDate
                        );

                        if (isRangeValid) {
                          setDateArr([...dateArr, ...DayDateArr]);
                        } else {
                          alert("Dates Already Selected, Please Select Again");
                        }
                      }}
                    />
                  ) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        sx={{
                          "& .MuiPickersDay-day": {
                            color: "red",
                          },
                          "& .MuiPickersDay-daySelected": {
                            color: "green",
                          },
                          "& .MuiPickersDay-dayDisabled": {
                            color: "pink",
                          },
                          width: "100%",
                        }}
                        disablePast={true}
                        value={value}
                        // boughtDate
                        slots={{ day: Day }}
                        slotProps={{
                          day: {
                            selectedDay: value,
                            TimelineData: formData?.mediaVariation?.Timeline,
                          },
                        }}
                        onChange={(newValue) => {
                          if (
                            formData?.ProductSubCategory ===
                            "643cda0c53068696706e3951" &&
                            newValue.day() !== 5
                          ) {
                            toast.error(
                              "Only Fridays are allowed for selection.",
                              {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              }
                            );
                            return;
                          }
                          setValueState(newValue);
                          let enddate = newValue.add(
                            ReturnDaysFromTimeline(
                              formData?.mediaVariation?.Timeline
                            ),
                            "day"
                          );
                          const DateExists = checkIfDateExists(
                            dateArr,
                            new Date(newValue).toJSON(),
                            new Date(enddate).toJSON()
                          );
                          if (DateExists) {
                            return toast.error(`Date Already Selected`, {
                              position: "top-center",
                              autoClose: 2000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                          } else {
                            setDateArr([
                              ...dateArr,
                              {
                                startDate: new Date(newValue).toJSON(),
                                endDate: new Date(enddate).toJSON(),
                                minOrderQuantityunit: Number(
                                  formData?.mediaVariation?.minOrderQuantityunit
                                ),
                                maxOrderQuantityunit: Number(
                                  formData?.mediaVariation?.maxOrderQuantityunit
                                ),
                                minTimeslotSeconds: Number(
                                  formData?.mediaVariation?.minTimeslotSeconds
                                ),
                                maxTimeslotSeconds: Number(
                                  formData?.mediaVariation?.maxTimeslotSeconds
                                ),
                              },
                            ]);
                          }
                        }}
                      />
                    </LocalizationProvider>
                  )}
                </Box>
                <Box>
                  {(dateArr?.length > 0 &&
                    formData?.mediaVariation?.Timeline === "Day") ||
                    formData?.mediaVariation?.Timeline === "One Time" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      {dateArr?.map((item, idx) => {
                        return (
                          <Box
                            key={idx}
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "flex",
                              width: "200px",
                              gap: "20px",
                              border: "1px solid #E3E3E3",
                              borderRadius: "10px",
                              padding: "0px 20px",
                            }}
                          >
                            <Typography
                              id="standard-basic"
                              variant="standard"
                              InputProps={{
                                disableUnderline: "true",
                                style: {
                                  color: "rgba(107, 122, 153)",
                                  fontSize: "14px",
                                },
                              }}
                              sx={{
                                width: "100%",
                                background: "transparent",
                                padding: "10px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {" "}
                              Date :
                              {new Date(item?.startDate).toLocaleDateString(
                                "en-US",
                                options
                              )}{" "}
                              to{" "}
                              {new Date(item?.endDate).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </Typography>

                            <Box
                              component="img"
                              src={CloseIconImg}
                              sx={{
                                cursor: "pointer",
                                height: "25px",
                                width: "25px",
                              }}
                              onClick={() => {
                                let temp = dateArr.filter(
                                  (items) => items !== item
                                );
                                setDateArr(temp);
                              }}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  ) : (
                    dateArr?.map((item, idx) => {
                      return (
                        <Box
                          key={idx}
                          sx={{
                            justifyContent: "space-between",
                            display: "flex",
                            mt: "30px",
                            width: "auto",
                            gap: "20px",
                            border: "1px solid #E3E3E3",
                            borderRadius: "10px",
                            padding: "0px 30px",
                          }}
                        >
                          <Typography
                            id="standard-basic"
                            variant="standard"
                            InputProps={{
                              disableUnderline: "true",
                              style: {
                                color: "rgba(107, 122, 153)",
                                fontSize: "14px",
                                padding: "7px",
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: "red",
                              },
                            }}
                            sx={{
                              width: "100%",
                              background: "transparent",
                              padding: "10px",
                              fontFamily: "Poppins",
                            }}
                          >
                            {" "}
                            From :
                            {new Date(item?.startDate).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </Typography>
                          <Typography
                            id="standard-basic"
                            variant="standard"
                            InputProps={{
                              disableUnderline: "true",
                              style: {
                                color: "rgba(107, 122, 153)",
                                fontSize: "14px",
                                padding: "7px",
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: "red",
                              },
                            }}
                            sx={{
                              width: "100%",
                              background: "transparent",
                              padding: "10px",
                              fontFamily: "Poppins",
                            }}
                          >
                            To :{" "}
                            {new Date(item?.endDate).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </Typography>
                          <Box
                            component="img"
                            src={CloseIconImg}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              let temp = dateArr.filter(
                                (items) => items !== item
                              );
                              setDateArr(temp);
                            }}
                          />
                        </Box>
                      );
                    })
                  )}
                </Box>
              </Box>
            ) : null}

            <Box className="field-box">
              <label>Location</label>
              <input
                type="text"
                name="location"
                value={formData?.location}
                onChange={handleInputChange}
                placeholder="Mumbai"
                style={{ width: "92%" }}
              />
            </Box>

            <Box className="field-box">
              <label>Listing Days</label>
              <input
                type="text"
                name="listperiod"
                value={formData?.listperiod}
                onChange={handleInputChange}
                placeholder="Listing Day"
                style={{ width: "92%" }}
              />
            </Box>

            <Box className="field-box">
              <label>Status</label>
              <select
                style={{
                  width: "92%",
                  height: "40px",
                  border: "1px solid #e8e8e8",
                  borderRadius: 2,
                  padding: "0 10px",
                  webkitAppearance: "none",
                }}
                name="status"
                value={formData?.status}
                onChange={handleInputChange}
              >
                <option value="">Select Option</option>
                <option value="Approved">Approved</option>
                <option value="pendingapproval">Pending Approval</option>
                <option value="Review">Review</option>
                <option value="Delist">Delist</option>
                <option value="Rejected">Rejected</option>
              </select>
              {formData.status === "Rejected" && (
                <input
                  required
                  type="text"
                  placeholder="Enter a reason for rejection"
                  name="rejectionReason"
                  value={formData.rejectionReason}
                  onChange={handleInputChange}
                />
              )}
              {formData.status === "Review" && (
                <input
                  type="text"
                  placeholder="Enter a reason for review"
                  name="reviewReason"
                  value={formData.reviewReason}
                  onChange={handleInputChange}
                />
              )}
            </Box>

            {formData.status === "Review" && (
              <Box className="field-box">
                <label>Navigation Status</label>
                <select
                  style={{
                    width: "92%",
                    height: "40px",
                    border: "1px solid #e8e8e8",
                    borderRadius: 2,
                    padding: "0 10px",
                    webkitAppearance: "none",
                  }}
                  defaultValue={"ProductInformation"}
                  name="reviewReasonNavigation"
                  value={formData.reviewReasonNavigation}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  <option value="ProductInformation">
                    Product Information
                  </option>
                  <option value="TechnicalInformation">
                    Technical Information
                  </option>
                  <option value="GoLive">Go Live</option>
                </select>
              </Box>
            )}
            <Box
              sx={{
                width: "100%",
              }}
            >
              {formData?.productType === "Media" ||
                ProductStatusForModal ===
                "Approved" ? null : ProductStatusForModal ===
                  "pendingapproval" || formData?.status === "Approved" ? (
                <Box
                  sx={{
                    my: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {formData?.productType === "Media" ? null : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        height: "auto",
                        maxHeight: "300px",
                        justifyContent: "center",
                        overflow: "scroll",
                      }}
                    >
                      {VisiblePoints ? (
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          {formData?.productType === "Voucher"
                            ? VoucherPoints.map((el, idx) => {
                              return (
                                <Box
                                  key={idx}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontSize: "10px",
                                    width: "100%",
                                    mb: "5px",
                                  }}
                                >
                                  <span
                                    style={{
                                      ...SubData,
                                      fontSize: "10px",
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    {el?.name}
                                  </span>
                                  <select
                                    className="input-field"
                                    style={{
                                      ...InputStyle,
                                      width: "30%",
                                      color: "black",
                                    }}
                                    variant="contained"
                                    defaultValue=""
                                    onChange={(e) =>
                                      handleInputChangeInput(
                                        el?.name,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option disabled="true" value="">
                                      -- Select --
                                    </option>
                                    {Object.keys(el?.Points).map(
                                      (pointName, pointIdx) => (
                                        <option
                                          key={pointIdx}
                                          value={pointName}
                                        >
                                          {pointName}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </Box>
                              );
                            })
                            : formData?.productType === "Product"
                              ? ProductPoints.map((el, idx) => {
                                return (
                                  <Box
                                    key={idx}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      fontSize: "10px",
                                      width: "100%",
                                      mb: "5px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        ...SubData,
                                        fontSize: "10px",
                                        textAlign: "left",
                                        color: "black",
                                      }}
                                    >
                                      {el?.name}
                                    </span>
                                    <select
                                      className="input-field"
                                      style={{
                                        ...InputStyle,
                                        width: "30%",
                                        color: "black",
                                      }}
                                      variant="contained"
                                      defaultValue=""
                                      onChange={(e) =>
                                        handleInputChangeInput(
                                          el?.name,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option disabled="true" value="">
                                        -- Select --
                                      </option>
                                      {Object.keys(el?.Points).map(
                                        (pointName, pointIdx) => (
                                          <option
                                            key={pointIdx}
                                            value={pointName}
                                          >
                                            {pointName}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </Box>
                                );
                              })
                              : null}
                          <Box>
                            <Typography
                              sx={{
                                ...SubData,
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              <b>Total Question</b>
                            </Typography>
                            {Object.keys(totalPoints).map(
                              (pointName, pointIdx) => (
                                <Box
                                  key={pointIdx}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontSize: "10px",
                                    width: "100%",
                                    mb: "5px",
                                  }}
                                >
                                  <span style={SubData}>
                                    {pointIdx + 1} : {pointName}:{" "}
                                  </span>{" "}
                                  <span style={SubData}>
                                    <b>{totalPoints[pointName]}</b>
                                  </span>
                                </Box>
                              )
                            )}
                          </Box>
                          <Typography
                            sx={{
                              ...SubData,
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <b>Total Selected Points Calculated :</b>{" "}
                            {sumOfPoints}
                          </Typography>
                          {/* <Typography sx={SubData}>
                            <b>Total Points Calculated :</b>{" "}
                            {lastamounttodisplay}
                          </Typography>
                          <Typography sx={SubData}>
                            <b>Total Percentage :</b> {credittoken.toFixed(2)}%
                          </Typography>
                          <Typography sx={SubData}>
                            <b> Total Amount For Credit :</b>{" "}
                            {credittoken <= 49
                              ? "0"
                              : credittoken > 49 && credittoken <= 74
                              ? "2,50,000"
                              : credittoken > 74 && credittoken <= 100
                              ? "5,00,000"
                              : null}
                          </Typography> */}
                        </Box>
                      ) : null}
                    </Box>
                  )}
                  {formData?.productType === "Media" ? null : (
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        sx={{
                          ...ButtonStyle,
                          textTransform: "none",
                          color: "#445FD2",
                          background: "#fff",
                          border: "1px solid #445FD2",
                          width: "auto",
                          height: "30px",
                        }}
                        onClick={() => {
                          if (!VisiblePoints) {
                            setVisiblePoints(true);
                          } else {
                            setVisiblePoints(false);
                          }
                        }}
                      >
                        {!VisiblePoints
                          ? "View More Points..."
                          : "View Less..."}
                      </Button>
                    </Box>
                  )}
                </Box>
              ) : null}
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box className="button-container">
                <button
                  className="cancel-button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="save-button"
                  onClick={() => {
                    UpdateProduct();
                  }}
                >
                  Save
                </button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <CommonDelete
        open={isDeleteModalOpen}
        onClose={handleDeleteClose}
        // onDelete={handleDelete}
        onDelete={() => {
          handleDelete(pid);
        }}
        text="Are you sure you want to delete this Product?"
        ButtonLabel="Delete"
        cancelButtonLabel="Cancel"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: {
            xl: "100%",
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "100%",
          },
          mx: "auto",
        }}
        mt={2}
      >
        <Stack>
          <StyledPagination
            size="medium"
            count={initialPaginationCount}
            color="primary"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default Marketplace;

const iconStyle = {
  color: "rgba(68, 95, 210, 1)",
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};

const ButtonStyle = {
  width: "100%",
  height: "40px",
  border: "none",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "1px 1px 5px 1px #ccc",
  },
  borderRadius: 2,
  fontFamily: "poppins",
  fontWeight: 400,
};

const SubData = {
  color: "#868686",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
};

const InputStyle = {
  color: "#818181",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
};
const FieldBoxInput = {
  width: "100%",
  border: "1px solid #e6e9ee",
  borderRadius: 3,
  fontFamily: "Poppins",
  fontWeight: 500,
  padding: 10,
  fontSize: 11,
};
const FieldBoxLabel = {
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: 11,
};

const CommonTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};
