import { Grid, Paper, Typography, Box, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import Indicator from "../../assets/Indicator.png";
import correctCheck from "../../assets/correctCheck.png";
import errorimg from "../../assets/errorimg.png";
import * as Styles from "../../common/Styles";
import "./SpeechBubbles.Module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useOnBoarding } from "../../Hooks/onBoarding/useOnBoarding";
import { async } from "react-input-emoji";
import { GetTokenFunc } from "../../utils/GetToken";
const Track = () => {
  const [boardingStatus, setBoardingStatus] = useState("");

  const [boarding, setBoarding] = useState();
  const [isPopupOpenRej, setIsPopupOpenRej] = useState(false);
  // const [sucessPopup, setSucessPoup] = useState(false);
  const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const [openSuccessPopupIndex, setOpenSuccessPopupIndex] = useState(null);
  const [moreDetails, setMoreDetails] = useState(false);

  const { mutate: onBoardingMutate, isLoading: onBoardingLoading } =
    useOnBoarding();
  const [isPopupOpen, setIsPopupOpen] = useState(
    new Array(boarding?.length).fill(false)
  );
  const [successPopup, setSuccessPopup] = useState({});
  const openPopup = (index) => {
    const updatedPopupState = [...isPopupOpen];
    updatedPopupState[index] = true;
    setIsPopupOpen(updatedPopupState);
  };

  const openSuccessPopup = (index) => {
    setSuccessPopup((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const closePopup = (index) => {
    const updatedPopupState = [...isPopupOpen];
    updatedPopupState[index] = false;
    setIsPopupOpen(updatedPopupState);
  };

  const closeSuccessPopup = (index) => {
    setSuccessPopup((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const openPopupRej = () => {
    setIsPopupOpenRej(true);
  };

  const { id } = useParams();
  const onBoardingData = async () => {
    await axios
      .get(`/api/v1/admin/CompanyOnBoardingDetailes?CompanyID=${id}`, {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setBoarding(res.data);
      })
      .catch((err) => {});
  };
  const AddOnBoardingStatus = async (selectedStatus) => {
    await axios
      .post(
        `/api/v1/admin/update-company-onboardingstatus`,
        {
          companyId: id,
          // OnboardingStatus: 'UNDER_REVIEW',
          OnboardingStatus: selectedStatus,
        },
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        onBoardingData();
      })
      .catch((err) => {});
  };
  const AddOnBoardingUnderReview = async () => {
    await axios
      .post(
        `/api/v1/admin/update-company-onboardingstatus`,
        {
          companyId: id,
          OnboardingStatus: "UNDER_REVIEW",
          // OnboardingStatus: selectedStatus,
        },
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        onBoardingData();
      })
      .catch((err) => {});
  };
  const closePopupRej = () => {
    setIsPopupOpenRej(false);
  };
  const successfullStatus = (selectedStatus) => {
    AddOnBoardingStatus(selectedStatus);
    closePopupRej(false);
    setIsPopupOpenRej(false);
  };
  const successReviewStatus = () => {
    AddOnBoardingUnderReview();
    closePopupRej(false);
    setIsPopupOpenRej(false);
  };
  useEffect(() => {
    onBoardingData();
    // AddOnBoardingUnderReview();
  }, []);
  return (
    <>
      <Paper elevation={0}>
        <Box>
          <Typography sx={Styles.chatText}>Onboarding Status </Typography>
          <Typography sx={Styles.chatTextSub}>
            Here is a step by step process of a particular company.
          </Typography>
        </Box>
        <Grid
          container
          sx={{
            borderLeft: "3px solid rgba(55, 93, 187, 1)",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            mt: 5,
          }}
        >
          {boarding?.data?.map((x, i) => {
            return (
              <>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "2rem",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        ...stepperbackDesign,
                        backgroundColor:
                          x?.StepStatus === "Done"
                            ? "rgba(234, 251, 231, 0.28)"
                            : "rgba(252, 236, 234, 1)",
                      }}
                    >
                      <Box
                        component="img"
                        src={Indicator}
                        sx={indicatorstyle}
                      ></Box>

                      <Box sx={{ display: "flex", gap: "1px" }}>
                        <Typography sx={stepDetails}>
                          {x?.StepName === "Bank Details"
                            ? "Pan Details"
                            : x?.StepName}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {x?.StepStatus === "Done" ? (
                          <>
                            <Box
                              onClick={() =>
                                openSuccessPopup(boarding.length - 1)
                              }
                              component={"img"}
                              src={correctCheck}
                              alt=""
                              sx={{ width: "25px", right: "25px" }}
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <Box
                              onClick={openPopupRej}
                              component={"img"}
                              src={errorimg}
                              alt=""
                              sx={{
                                width: "20px",
                                right: "20px",
                                marginRight: 2,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {isPopupOpenRej && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "56%",
                          ml: "auto",
                          mt: -15,
                        }}
                      >
                        {x?.StepStatus !== "Done" && (
                          <>
                            <Box
                              sx={{
                                color: "rgba(223, 228, 247, 1)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              -----------------
                            </Box>

                            <Box sx={messageBox}>
                              <div style={messageBoxText} />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography sx={errDesign}>ERROR</Typography>
                                <Typography sx={continueDesgin}>
                                  We are unable to continue the process.
                                </Typography>
                                <Typography sx={reson}>Reason</Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    mt: 1,
                                  }}
                                >
                                  <Box
                                    className="marketplace-filters"
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box>
                                      <select
                                        value={boardingStatus}
                                        onChange={(e) =>
                                          setBoardingStatus(e.target.value)
                                        }
                                      >
                                        <option value="UNVERIFIED">
                                          UNVERIFIED
                                        </option>
                                        <option value="GST">GST</option>
                                        <option value="BANK_DETAILS">
                                          PAN_DETAILS
                                        </option>
                                        {/* <option value="REVERSE_DROP">
                                          REVERSE_DROP
                                        </option> */}
                                        <option value="COURT_CASE">
                                          COURT_CASE
                                        </option>
                                        <option value="TNC">TNC</option>
                                        <option value="PAYMENT">PAYMENT</option>
                                        <option value="UNDER_REVIEW">
                                          UNDER_REVIEW
                                        </option>
                                        {/* <option value="FORWARD_DROP">
                                          FORWARD_DROP
                                        </option> */}
                                        <option value="ONBOARDING_COMPLETE">
                                          ONBOARDING_COMPLETE
                                        </option>
                                      </select>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={btnSuccessful}
                                      // onClick={successfullStatus}
                                      onClick={() =>
                                        successfullStatus(boardingStatus)
                                      }
                                    >
                                      Successful
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      sx={btnReject}
                                      onClick={closePopupRej}
                                    >
                                      Reject
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                    {successPopup[i] && i === boarding.length - 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "56%",
                          ml: "auto",
                          mt: -10,
                        }}
                      >
                        {x?.StepStatus === "Done" && (
                          <>
                            <Box
                              sx={{
                                color: "rgba(223, 228, 247, 1)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              -----------------
                            </Box>

                            <Box sx={messageBox}>
                              <div style={messageBoxText} />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography sx={errDesign}>
                                  Change Status
                                </Typography>
                                <Typography sx={continueDesgin}>
                                  You can update the status of this company.
                                </Typography>
                                <Typography sx={reson}>Reason</Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "center",
                                    mt: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={btnSuccessful}
                                    onClick={successReviewStatus}
                                  >
                                    under Review
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    sx={btnReject}
                                    onClick={() => closeSuccessPopup(i)}
                                  >
                                    Reject
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Box sx={boxStyle}>
                      {x?.Name?.length > 0 && (
                        <>
                          {/* <Box
                          sx={{
                            borderRadius: '50%',
                            background: 'rgba(55, 93, 187, 1)',
                            height: '10px',
                            width: '10px',
                            position: 'absolute',
                            top: '40%',
                            left: '-0.55%',
                          }}
                        ></Box> */}
                          <Box sx={{ display: "flex", gap: "40px" }}>
                            <Typography sx={headTextStepper}>Name </Typography>
                            <Typography sx={subTextStepper}>
                              {x.Name}
                            </Typography>
                          </Box>
                        </>
                      )}
                      {x?.Phone_Number?.length > 0 && (
                        <Box sx={{ display: "flex", gap: "40px" }}>
                          <Typography sx={headTextStepper}>
                            Phobe Number
                          </Typography>
                          <Typography sx={subTextStepper}>
                            {x.Phone_Number}
                          </Typography>
                        </Box>
                      )}
                      {x?.Category?.length > 0 && (
                        <Box sx={{ display: "flex", gap: "40px" }}>
                          <Typography sx={headTextStepper}>category</Typography>
                          <Typography sx={subTextStepper}>
                            {x.Category}
                          </Typography>
                        </Box>
                      )}
                      {x?.E_mail?.length > 0 && (
                        <Box sx={{ display: "flex", gap: "40px" }}>
                          <Typography sx={headTextStepper}> E-mail </Typography>
                          <Typography sx={subTextStepper}>
                            {x.E_mail}
                          </Typography>
                        </Box>
                      )}
                      {x?.GST?.length > 0 && (
                        <Box sx={{ display: "flex", gap: "40px" }}>
                          <Typography sx={headTextStepper}> GST </Typography>
                          <Typography sx={subTextStepper}>{x.GST}</Typography>
                        </Box>
                      )}
                      {x?.Account_No?.length > 0 && (
                        <Box sx={{ display: "flex", gap: "40px" }}>
                          <Typography sx={headTextStepper}>
                            {" "}
                            Account No{" "}
                          </Typography>
                          <Typography sx={subTextStepper}>
                            {x.Account_No}
                          </Typography>
                        </Box>
                      )}
                      {x?.Branch?.length > 0 && (
                        <Box sx={{ display: "flex", gap: "40px" }}>
                          <Typography sx={headTextStepper}> Branch </Typography>
                          <Typography sx={subTextStepper}>
                            {x.Branch}
                          </Typography>
                        </Box>
                      )}
                      {x?.IFSC_Code?.length > 0 && (
                        <Box sx={{ display: "flex", gap: "40px" }}>
                          <Typography sx={headTextStepper}>
                            {" "}
                            IFSC Code{" "}
                          </Typography>
                          <Typography sx={subTextStepper}>
                            {x.IFSC_Code}
                          </Typography>
                        </Box>
                      )}
                      {x?.message?.length > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Typography sx={subTextStepper}>
                            {x?.StepName === "Membership Plan"
                              ? x?.message === "basic"
                                ? "Standard"
                                : "Advanced"
                              : x?.message}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </>
            );
          })}
        </Grid>
        <Button
          onClick={() => {
            setMoreDetails(!moreDetails);
          }}
          variant="outlined"
        >
          More Details
        </Button>
        {moreDetails &&
          boarding?.steps?.map((x, i) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "2rem",
                  mt: "2rem",
                }}
              >
                <Typography>{i + 1}</Typography>
                <Box sx={{}}>
                  <Typography>{x?.step}</Typography>
                  <Typography>{x?.message}</Typography>
                  <Typography
                    sx={{
                      color:
                        x?.status?.toLowerCase() === "success"
                          ? "green"
                          : "red",
                    }}
                  >
                    {x?.status?.toLowerCase()}
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </Paper>
    </>
  );
};

export default Track;

const stepDetails = {
  color: "var(--light-mode-neutral-800, #32324D)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 11.887,
  fontStyle: "normal",
  fontWeight: 400,
  marginTop: {
    xl: "-0.3rem",
    lg: "-0.3rem",
    md: "-0.3rem",
    sm: "0rem",
    xs: "0rem",
  },
  marginLeft: {
    xl: "2rem",
    lg: "2rem",
    md: "2rem",
    sm: "2rem",
    xs: "2rem",
  },
  marginRight: {
    xl: "0rem",
    lg: "0rem",
    md: "0rem",
    sm: "2rem",
    xs: "2rem",
  },
};
const boxStyle = {
  width: 453,
  height: "auto",
  p: 2,
  borderRadius: 4,
  border: "1.5px solid rgba(68, 95, 210, 0.17)",
  ml: 2,
  mt: 1,
  display: "flex",
  flexDirection: "column",
};
const headTextStepper = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: 10,
  fontStyle: "normal",
  fontWeight: 600,
  width: "20%",
};
const subTextStepper = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: 10,
  fontStyle: "normal",
  fontWeight: 400,
};
const stepperbackDesign = {
  width: 494,
  height: 46,
  flexShrink: 0,
  borderRadius: "5px",
  background: "rgba(234, 251, 231, 0.28)",
  display: "flex",
  ml: -2,
  mt: -2,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
};
const errDesign = {
  color: "rgba(242, 30, 30, 0.93)",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  lineHeight: "20px",
};
const continueDesgin = {
  color: "#8C8C8C",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: 10,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  lineHeight: "10px",
};
const reson = {
  color: "#8C8C8C",
  fontFamily: "Poppins",
  fontSize: 10,
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "167.188%",
  textAlign: "center",
};
const btnSuccessful = {
  width: "75px",
  height: "17px",
  flexShrink: 0,
  background: "rgba(68, 95, 210, 1)",
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "8px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
};
const btnReject = {
  width: "75px",
  height: "17px",
  flexShrink: 0,
  border: "1px solid rgba(241, 91, 91, 1)",
  color: "#F15B5B",
  fontFamily: "Poppins",
  fontSize: "8px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
};
const messageBox = {
  position: "relative",
  width: "235px",
  height: "auto",
  borderRadius: 3,
  background: "rgba(235, 235, 235, 0.24)",
  boxShadow: "0px 4px 4px 0px rgba(205, 205, 205, 0.25)",
  p: 2,
};
const messageBoxText = {
  content: "",
  position: "absolute",
  left: 0,
  top: "50%",
  width: 0,
  height: 0,
  border: "20px solid transparent",
  borderRight: 0,
  borderRight: "20px solid rgba(250, 250, 250, 1)",
  marginTop: "-20px",
  marginLeft: "-40px",
};
const indicatorstyle = {
  height: "20px",
  width: "20px",
  background: "white",
  color: "white",
  fontSize: "25px",
  borderRadius: "50%",
  display: "inline-block",
  position: "absolute",
  top: "-8%",
  left: "-1%",
  border: "2px solid rgba(55, 93, 187, 1)",
};
