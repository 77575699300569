import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Paper,
  Grid,
  FormControl,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { RadialChart, DiscreteColorLegend } from "react-vis";
import { IconButton } from "@mui/material";
import FigmaIcon from "../../assets/figma-icon.png";
import { Delete, Edit } from "@mui/icons-material";
import BxiCoinIcon from "../../assets/BXI-coin-icon.svg";
import Popup from "../Layouts/Popup/Popup";
import filter from "../../assets/filter.png";
import * as Styles from "../../common/Styles";
import CorrectGreenIcon from "../../assets/correct-green-icon.svg";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GetTokenFunc } from "../../utils/GetToken";
import Searchbar from "../../common/Searchbar";

const Memberlisting = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },
    // "& .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
    //   color: "#1976d2",
    //   display: "none",
    // },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [activePage, setActivePage] = useState("local");
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");
  const [chartFilterData, setChartFilterData] = useState("");
  const [chartFilter, setChartFilter] = useState("Verified");
  const [currentPage, setCurrentPage] = useState(1);
  const [statusfilter, setStatusfilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [searchData, setSearchData] = useState("");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [loader, setLoader] = useState(true);

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const parts = dateString.split(", ");
    if (parts.length === 2) {
      const datePart = parts[0];
      return datePart;
    }
    return dateString;
  };

  const categories = [
    "Airline Tickets",
    "Hotels",
    "Lifestyle",
    "Office Supply",
    "Restaurant & QSR",
    "Entertainment & Events",
    "Textile",
    "Media",
    "FMCG",
    "Electronics",
    "Mobility",
    "Others",
  ];

  const size = {
    width: 800,
    height: 400,
  };
  const [chart, setChart] = useState([]);
  const [members, setMembers] = useState();
  const [LastActive, setLastActive] = useState();
  const [cartDatas, setChartDatas] = useState();
  const [totalMembers, setTotalMembers] = useState([]);
  // const chartData = async () => {
  //   await axios
  //     .post(
  //       `AdminCompanyDetails/AllCategoryListedCompanyData?${chartFilter}=${chartFilter}`
  //       // `/AdminCompanyDetails/AllCategoryListedCompanyData?Verified=Verified`
  //       // Rejected, UnderReview, Verified, BasicPlane, PremiumPlane
  //     )
  //     .then((res) => {
  //       setChart(res.data);
  //     })
  //     .catch((err) => {});
  // };
  const data = [
    {
      id: 1,
      value: 120,
      label: "Airline Tickets",
      subLabel: 120,
      color: "#FBB4AE",
      style: { fill: "#FBB4AE" },
    },
    {
      id: 2,
      value: 300,
      label: "Hotels",
      subLabel: 300,
      color: "#B3CDE3",
      style: { fill: "#B3CDE3" },
    },
    {
      id: 3,
      value: 200,
      label: "Lifestyle",
      subLabel: 200,
      color: "#CCEBC5",
      style: { fill: "#CCEBC5" },
    },
    {
      id: 4,
      value: 150,
      label: "Office Supply",
      subLabel: 150,
      color: "#DECBE4",
      style: { fill: "#DECBE4" },
    },
    {
      id: 5,
      value: 240,
      label: "Restaurant & QSR",
      subLabel: 240,
      color: "#FED9A6",
      style: { fill: "#FED9A6" },
    },
    {
      id: 6,
      value: 100,
      label: "Entertainment & Events",
      subLabel: 100,
      color: "#FFFFCC",
      style: { fill: "#FFFFCC" },
    },
    {
      id: 7,
      value: 222,
      label: "Textile",
      subLabel: 222,
      color: "#E5D8BD",
      style: { fill: "#E5D8BD" },
    },
    {
      id: 8,
      value: 300,
      label: "Media",
      subLabel: 300,
      color: "#FDDAEC",
      style: { fill: "#FDDAEC" },
    },
    {
      id: 9,
      value: 80,
      label: "FMCG",
      subLabel: 80,
      color: "#F2F2F2",
      style: { fill: "#F2F2F2" },
    },
    {
      id: 10,
      value: 240,
      label: "Electronics",
      subLabel: 240,
      color: "#FBB4AE",
      style: { fill: "#FBB4AE" },
    },
    {
      id: 11,
      value: 210,
      label: "Mobility",
      subLabel: 210,
      color: "#B3CDE3",
      style: { fill: "#B3CDE3" },
    },
    {
      id: 12,
      value: 260,
      label: "Others",
      subLabel: 260,
      color: "#CCEBC5",
      style: { fill: "#CCEBC5" },
    },
  ];
  const MembersData = async (currentPage, status, categoryFilter) => {
    await axios
      .get(
        `/api/v1/admin/AllCompanyDetails?page=${currentPage}&status=${status}&category=${categoryFilter}&search=${searchData}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        setMembers(res.data);
        setLoader(false);
      })
      .catch((err) => {});
  };

  const LastActiveData = async () => {
    await axios
      .get(`/api/v1/admin/AdminCompanyDetails/LastActiveCompanyDate`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setLastActive(res.data.data);
      })
      .catch((err) => {});
  };

  const GetAllTotalUserCount = async () => {
    await axios
      .get(`/AdminCompanyDetails/GetAllTotalUserCount`)
      .then((res) => {
        setTotalMembers(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    GetAllTotalUserCount();
  }, []);

  useEffect(() => {
    const chartData = async () => {
      try {
        const response = await axios.post(
          `AdminCompanyDetails/AllCategoryListedCompanyData?${chartFilter}=${chartFilter}`
        );
        setChart(response.data);
      } catch (err) {
        // Handle errors here
        console.error(err);
      }
    };
    chartData();
  }, [chartFilterData, chartFilter]);

  useEffect(() => {
    MembersData(currentPage, statusfilter, categoryFilter);
    setLoader(true);
  }, [currentPage, statusfilter, categoryFilter, searchData]);

  useEffect(() => {
    LastActiveData();
  }, []);

  useEffect(() => {
    if (members && members?.finalCount !== undefined) {
      setInitialPaginationCount(members?.finalCount);
    }
  }, [members?.finalCount]);

  const CategoryName = [
    {
      id: "63e38bddcc4c02b8a0c94b8a",
      CategoryName: "Others",
    },
    {
      id: "63e38b9ccc4c02b8a0c94b6f",
      CategoryName: "Lifestyle",
    },
    {
      id: "63e38bb9cc4c02b8a0c94b7b",
      CategoryName: "FMCG",
    },
    {
      id: "63e38b91cc4c02b8a0c94b69",
      CategoryName: "Media",
    },
    {
      id: "63e38ba3cc4c02b8a0c94b72",
      CategoryName: "Hotel",
    },
    {
      id: "63e38bbfcc4c02b8a0c94b7e",
      CategoryName: "Airlines Tickets",
    },
    {
      id: "63e38bc6cc4c02b8a0c94b81",
      CategoryName: "Office Supply",
    },
    {
      id: "63e38bb3cc4c02b8a0c94b78",
      CategoryName: "Mobility",
    },
    {
      id: "63e38bcecc4c02b8a0c94b84",
      CategoryName: "Electronics",
    },
    {
      id: "63e38b96cc4c02b8a0c94b6c",
      CategoryName: "Textile",
    },
    {
      id: "64218b189fe1b6ae750c11bd",
      CategoryName: "Entertainment & Events",
    },
    {
      id: "63e38bd5cc4c02b8a0c94b87",
      CategoryName: "QSR",
    },
  ];

  const newdata = [];
  let indexNo = 1;
  for (const key in chart?.data) {
    if (chart?.data.hasOwnProperty(key)) {
      const dataKey = key;
      const dataValue = chart?.data[key];
      if (dataValue > 0) {
        const newObj = {
          id: indexNo,
          value: dataValue,
          label: dataKey,
        };
        newdata.push(newObj);
        indexNo++;
      }
    }
  }

  // const newdataWeight = [];
  // let indexNoWeight = 1;
  // for (const key in chart?.weights) {
  //   if (chart?.data.hasOwnProperty(key)) {
  //     const dataKey = key;
  //     const dataValue = chart?.weights[key];
  //     if (dataValue > 0) {
  //       const newObj = {
  //         id: indexNoWeight,
  //         value: dataValue,
  //         label: dataKey,
  //       };
  //       newdataWeight.push(newObj);
  //       indexNoWeight++;
  //     }
  //   }
  // }

  const handleEditButtonClick = () => {
    let dummy = (
      <Paper elevation={0} sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <FormLabel>KYC</FormLabel>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={chartFilter}
              value={chartFilter}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Verified"
                control={<Radio />}
                label="Onboarding Complete"
                onClick={() => {
                  setChartFilter("Verified");
                  setIsOpen(false);
                }}
              />
              <FormControlLabel
                value="EMAIL"
                control={<Radio />}
                label="Email Verification"
                onClick={() => {
                  setChartFilter("EMAIL");
                  setIsOpen(false);
                }}
              />
              <FormControlLabel
                value="GST"
                control={<Radio />}
                label="GST Details"
                onClick={() => {
                  setChartFilter("GST");
                  setIsOpen(false);
                }}
              />
              <FormControlLabel
                value="BANK_DETAILS"
                control={<Radio />}
                label="Pan Details"
                onClick={() => {
                  setChartFilter("BANK_DETAILS");
                  setIsOpen(false);
                }}
              />
              <FormControlLabel
                value="PAYMENT"
                control={<Radio />}
                label="Payment"
                onClick={() => {
                  setChartFilter("PAYMENT");
                  setIsOpen(false);
                }}
              />
              <FormControlLabel
                value="TNC"
                control={<Radio />}
                label="T&C"
                onClick={() => {
                  setChartFilter("TNC");
                  setIsOpen(false);
                }}
              />
              <FormControlLabel
                value="UnderReview"
                control={<Radio />}
                label="UnderReview"
                onClick={() => {
                  setChartFilter("UnderReview");
                  setIsOpen(false);
                }}
              />
              <FormControlLabel
                value="Rejected"
                control={<Radio />}
                label="Rejected"
                onClick={() => {
                  setChartFilter("Rejected");
                  setIsOpen(false);
                }}
              />
              <FormControlLabel
                value="Cancelled"
                control={<Radio />}
                label="Cancelled"
                onClick={() => {
                  setChartFilter("Cancelled");
                  setIsOpen(false);
                }}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: 2 }}
        >
          <FormLabel>Membership</FormLabel>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={chartFilter}
              value={chartFilter}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="BasicPlane"
                control={<Radio />}
                label="Standard"
                onClick={() => {
                  setChartFilter("BasicPlane");
                  setIsOpen(false);
                }}
              />
              <FormControlLabel
                value="PremiumPlane"
                control={<Radio />}
                label="Advanced"
                onClick={() => {
                  setChartFilter("PremiumPlane");
                  setIsOpen(false);
                }}
              />
            </RadioGroup>
            {/* <Button
              variant="contained"
              value="All"
              label="All "
              onClick={(e) => {
                setChartFilter(e.target.value);
                setIsOpen(false);
              }}
            >
              Apply
            </Button> */}
          </FormControl>
        </Box>
        {/* <Box className="button-container">
          <button className="save-button">Apply</button>
          <button
            className="cancel-button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </button>
        </Box> */}
      </Paper>
    );
    setContent(dummy);
    setIsOpen(true);
  };
  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "590px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box className="page-heading-section">
        <Box className="page-heading" mb={2}>
          <h5>Company list</h5>
          <Typography>
            Members and its Category Classification Listed Below
          </Typography>
        </Box>
      </Box>
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 1 }}>
        <Box
          className="products-filters-row"
          sx={{ justifyContent: "space-between" }}
        >
          {/* {activePage == 'member' ? (
            <Box className="total-products-badge"></Box>
          ) : (
            <Box
              className="total-products-badge"
              sx={{
                bgcolor: 'transparent',
              }}
            ></Box>
          )} */}

          {activePage == "member" ? (
            <Box sx={buddge}>
              <Typography sx={textBudge}>
                {members instanceof Object ? (
                  <Typography sx={textBudge}>
                    Total Members : {members?.MemberCount}
                  </Typography>
                ) : (
                  <Typography sx={textBudge}>Total Members: 0</Typography>
                )}
              </Typography>
            </Box>
          ) : (
            <Box></Box>
          )}
          {activePage == "member" ? (
            <Searchbar width={"25%"} onSendData={handleChildData} />
          ) : null}

          <Box className="total-products-badge custom-tab">
            <span
              onClick={() => setActivePage("local")}
              style={{ color: activePage == "local" && "#445FD2" }}
            >
              Category
            </span>
            <span
              onClick={() => setActivePage("member")}
              style={{ color: activePage == "member" && "#445FD2" }}
            >
              Members
            </span>
          </Box>
          {/* <Box className="total-products-badge">Add New Member</Box> */}
          {activePage == "local" ? (
            <Box
              sx={Styles.filtersDesign}
              onClick={() => {
                handleEditButtonClick();
              }}
            >
              <img
                src={filter}
                alt=""
                style={{ height: "auto", width: "15px" }}
              />
              Filters
            </Box>
          ) : (
            <Box className="marketplace-filters">
              <Box
                sx={{
                  // bgcolor: "red",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <label style={{ marginTop: "10px" }}>Status</label>
                <select
                  value={statusfilter}
                  onChange={(e) => setStatusfilter(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="EMAIL">Email Verification</option>
                  <option value="GST">GST</option>
                  <option value="BANK_DETAILS">PAN_DETAILS</option>
                  <option value="TNC">TNC</option>
                  <option value="PAYMENT">PAYMENT</option>
                  <option value="UNDER_REVIEW">UNDER_REVIEW</option>
                  <option value="ONBOARDING_COMPLETE">
                    ONBOARDING_COMPLETE
                  </option>
                </select>
              </Box>
              <Box
                sx={{
                  // bgcolor: "red",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <label style={{ marginTop: "10px" }}>Category</label>
                <select
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="63e38b9ccc4c02b8a0c94b6f">Lifestyle</option>
                  <option value="63e38b96cc4c02b8a0c94b6c">Textile</option>
                  <option value="63e38bb3cc4c02b8a0c94b78">Mobility</option>
                  <option value="63e38bb9cc4c02b8a0c94b7b">FMCG</option>
                  <option value="63e38bc6cc4c02b8a0c94b81">
                    Office Supply
                  </option>
                  <option value="63e38bcecc4c02b8a0c94b84">Electronics</option>
                  <option value="63e38ba3cc4c02b8a0c94b72">Hotel</option>
                  <option value="63e38bd5cc4c02b8a0c94b87">QSR</option>
                  <option value="63e38bbfcc4c02b8a0c94b7e">
                    Airlines Tickets
                  </option>
                  <option value="63e38b91cc4c02b8a0c94b69">Media</option>
                  <option value="64218b189fe1b6ae750c11bd">
                    Entertainment & Events
                  </option>
                  <option value="63e38bddcc4c02b8a0c94b8a">Others</option>
                </select>
              </Box>
            </Box>
          )}
          <Popup
            openPopup={isOpen}
            popupTitle="Filters"
            popupContent={content}
            handleClose={() => {
              setIsOpen(false);
            }}
          />
        </Box>

        {activePage == "local" ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "40px",
                mt: -2,
              }}
            >
              {newdata.length > 0 ? (
                <PieChart
                  series={[
                    {
                      arcLabel: (item) => `${item.value}`,
                      data: newdata,
                    },
                  ]}
                  colors={[
                    "#52595D",
                    "#9B9A96",
                    "#98AFC7",
                    "#2F539B",
                    "#2916F5",
                    "#00FFFF",
                    "#007C80",
                    "#808000",
                    "#3F9B0B",
                    "#87F717",
                    "#FBB117",
                    "#622F22",
                  ]}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: "#000",
                      fontWeight: "bold",
                      fontSize: "8px",
                    },
                  }}
                  {...size}
                />
              ) : (
                <Box sx={setBox}>
                  <img
                    src={recyclebin}
                    alt="recycle"
                    style={{ height: "auto", width: "23%" }}
                  />
                  <Typography sx={empty}>Data is empty</Typography>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 1 }}>
            {loader ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 5,
                  width: "100%",
                  // mr: '150%',
                  ml: "500px",
                }}
              >
                <PageLoader />
              </Box>
            ) : (
              <>
                <table className="common-table">
                  <thead>
                    <tr>
                      <td width={"2%"}>No.</td>
                      <td align="left" width={"15%"}>
                        Company Name
                      </td>
                      <td width={"10%"}>Email</td>
                      {/* <td width={"10%"}>gst</td> */}
                      <td width={"10%"}>Members</td>
                      <td width={"10%"}>phone</td>
                      <td width={"10%"}>Date</td>
                      <td width={"10%"}>Last Active</td>
                      <td width={"10%"}>Location</td>
                      <td width={"10%"}>Membership</td>
                      <td width={"13%"}>Status</td>
                      <td width={"2%"}>KYC</td>
                      {/* <td width={"10%"}>Action</td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {members?.companyDetails?.map((x, i) => {
                      return (
                        <tr>
                          <td
                            align="left"
                            className="font-10-400"
                            style={{ width: "30px", height: "30px" }}
                          >
                            {(currentPage - 1) * 20 + i + 1}
                          </td>
                          <td align="left" className="product-title-colum">
                            <img
                              src={x?.CompanyAvatar?.url}
                              alt=""
                              style={{ width: "30px", height: "30px" }}
                            />
                            <Box className="product-title-box">
                              <label>{x?.companyName}</label>
                              <span>
                                Category Name :{" "}
                                {CategoryName.map((category) =>
                                  category?.id === x?.companyType
                                    ? category.CategoryName
                                    : null
                                )}
                              </span>
                            </Box>
                          </td>
                          <td className="font-10-400">{x?.email}</td>
                          {/* {x?.gst?.length > 0 ? (
                            <td className="font-10-400">{x?.gst}</td>
                            ) : (
                              <td className="font-10-400">NA</td>
                            )} */}
                          <td className="font-10-400">
                            {totalMembers
                              .filter((member) => member?.company === x?._id)
                              .reduce(
                                (acc, member) => acc + (member.Users || 0),
                                0
                              )}
                          </td>

                          <td className="font-10-400">{x?.phone} </td>
                          <td className="font-10-400">
                            {new Date(x?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                timeZone: "UTC",
                              }
                            )}
                          </td>
                          <td className="font-10-400">
                            {LastActive?.filter(
                              (item) => item?.loginUser === x?._id
                            ).reduce((idx, item) => {
                              return <span>{formatDate(item?.lastSeen)}</span>;
                            }, "--")}
                          </td>
                          <td className="font-10-400">
                            {x?.CompanyAddress?.State
                              ? x?.CompanyAddress?.State
                              : "--"}
                          </td>
                          <td className="font-10-400">
                            {x?.memberships?.length > 0 ? (
                              <>
                                {x?.memberships?.at(x?.memberships?.length - 1)
                                  ?.MembershipPlan?.PlanType === "basic"
                                  ? "Standard"
                                  : "Advanced"}
                              </>
                            ) : x?.ChoosedPlan ? (
                              <>
                                {x?.ChoosedPlan === "basic"
                                  ? "Standard"
                                  : "Advanced"}
                              </>
                            ) : (
                              "--"
                            )}
                          </td>
                          <td className="font-10-400">
                            {x?.companyOnboardingStatus === "BANK_DETAILS"
                              ? "PAN_DETAILS"
                              : x?.companyOnboardingStatus
                              ? x?.companyOnboardingStatus
                              : "EMAIL_VERIFICATION"}
                          </td>
                          <td>
                            <td className="font-10-400">
                              {x?.kyc !== false ? (
                                <CheckIcon sx={{ color: "green" }} />
                              ) : (
                                <CloseIcon sx={{ color: "red" }} />
                              )}{" "}
                            </td>
                          </td>
                          {/* <td>
                            <VisibilityIcon sx={{ color: "#445FD2" }} /> */}
                          {/* <IconButton aria-label="delete" style={iconStyle}>
                              <Delete />
                            </IconButton>
                            <IconButton aria-label="edit" style={iconStyle}>
                              <Edit />
                            </IconButton> */}
                          {/* </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    mx: "auto",
                  }}
                  mt={2}
                >
                  <Stack>
                    <StyledPagination
                      size="medium"
                      count={initialPaginationCount}
                      color="primary"
                      showFirstButton
                      showLastButton
                      page={currentPage}
                      onChange={handlePageChange}
                      strokeWidth={currentPage}
                    />
                  </Stack>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>

      {/* <CustomDiscreteColorLegend items={categories} /> */}
    </Paper>
  );
};

export default Memberlisting;

const iconStyle = {
  color: "rgba(68, 95, 210, 1)",
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  // mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "400px",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  // lineHeight: 20,
};
const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};
